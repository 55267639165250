import { useDispatch, useSelector } from "react-redux";
import { setBroker } from "../store";
import { FREEDOM_TYPE, INTERACTIVE_TYPE } from "../constants/constants";

function useBroker(dataProvider) {
  const dispatch = useDispatch();
  let broker = useSelector((state) => {
    return state.file.broker;
  });

  if (broker === "") {
    const currentBroker = JSON.parse(localStorage.getItem("broker"));
    dispatch(setBroker(currentBroker));
  }

  let brokerType = "";
  if (broker === "freedom") {
    brokerType = FREEDOM_TYPE;
  } else if (broker === "interactive") {
    brokerType = INTERACTIVE_TYPE;
  }

  if (dataProvider) {
    if (dataProvider === FREEDOM_TYPE) {
      broker = "freedom";
    } else if (dataProvider === INTERACTIVE_TYPE) {
      broker = "interactive";
    }
  }

  return { broker, brokerType };
}

export default useBroker;
