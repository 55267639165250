import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { GoSync } from 'react-icons/go';
import Accordion from '../components/Accordion';
import ImageShow from '../components/ImageShow';
import Link from '../components/Link';
import useToken from '../hooks/use-token';
import { useReferralInfoQuery } from '../store';

function ReferralPage() {
  const [referralUrl, setRefferalUrl] = useState('');
  const token = useToken();
  const param = { token };
  const { data, error, isFetching } = useReferralInfoQuery(param);

  const [isCopied, setIsCopied] = useState(false);
   
  const [mobileScreen, setMobileScreen] = useState(false);

  useEffect(() => {
    if (data) {
      setRefferalUrl(window.location.origin + '?referral=' + data.code);
    }
  }, [data]);

  const instructions = (
    <ol className="list-decimal">
      <li>
        За кожного нового клієнта, який перейшов за вашим посиланням та
        скористається нашими послугами, ви отримуватимете знижку в розмірі 10%
        (20$ = 20 бонусів) на ваш наступний податковий розрахунок.
      </li>
      <li>
        Кожному вашому другу також надається 10% знижка (20$ = 20 бонусів) на їх
        перший податковий розрахунок.
      </li>
      <li>
        Знижка накопичується та може бути використана в межах однієї послуги,
        але не більше 200$ за послугу.
      </li>
      <li>
        Знижка може бути використана для формування податкових декларацій та
        інших послуг, що надаються сервісом osa.tax
      </li>
      <li>
        У разі набуття вами статусу "партнера", всі невикористані бонуси
        анулюються
      </li>
    </ol>
  );

  const items = [
    {
      id: 0,
      label: <>Умови реферальної програми { mobileScreen && <br /> } “Податкові друзі</>,
      content: instructions,
    },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await navigator.clipboard.writeText(referralUrl);
      setIsCopied(true);
    } catch (err) {
      console.error('Failed to copy!', err);
    }
  };

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
  }, [isCopied]);

  
  useEffect(() => {
    const handleResize = () => {
      setMobileScreen(window.innerWidth < 460);
    };
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  let content;
  if (isFetching) {
    content = <GoSync className="animate-spin inline-block text-green-700" />;
  } else if (error) {
    console.error('Error loading data...');
  } else {
    content = (
      <>
        <div className="grid grid-cols-2 xl:grid-cols-4 gap-[10px]">
          <div className="p-7 flex flex-col rounded-[30px] text-white bg-gradient-to-r from-cyan-650 to-cyan-75 xs:p-5">
            <ImageShow
              className="w-[24px] mb-8"
              image={{ url: 'user', label: 'user' }}
            />
            <p className="text-sm xs:mt-auto">Зареєстровано:</p>
            <p className="text-2xl">{data.referralCount}</p>
          </div>
          <div className="p-7 flex flex-col rounded-[30px] bg-gradient-to-r from-gray-250 to-gray-50 xs:p-5">
            <ImageShow
              className="w-[24px] mb-8"
              image={{ url: 'card-gray', label: 'card gray' }}
            />
            <p className="text-sm xs:mt-auto">Сплатили { mobileScreen && <br /> } послугу:</p>
            <p className="text-2xl">{data.referralPayedServiceCount}</p>
          </div>
          <div className="p-7 flex flex-col rounded-[30px] bg-gradient-to-r from-gray-250 to-gray-50 xs:p-5">
            <ImageShow
              className="w-[24px] mb-8"
              image={{ url: 'star', label: 'star' }}
            />
            <p className="text-sm  xs:mt-auto">Нараховано бонусів всього:</p>
            <p className="text-2xl">{data.acquiredBonuses}</p>
          </div>
          <div className="p-7 flex flex-col rounded-[30px] bg-gradient-to-r from-gray-250 to-gray-50 xs:p-5">
            <ImageShow
              className="w-[24px] mb-8"
              image={{ url: 'half-star', label: 'half star' }}
            />
            <p className="text-sm  xs:mt-auto">Залишок { mobileScreen && <br /> } бонусів:</p>
            <p className="text-2xl">{data.balanceBonuses}</p>
          </div>
        </div>
        <div className="flex flex-row mt-16 xs:mt-10">
          <div className="xl:basis-1/2 relative pb-10">
            <label className="text-gray-350 pb-7">
              Ваше реферальне посилання:
            </label>
            <form
              onSubmit={handleSubmit}
              className="relative flex bg-gray-75 max-w-[360px] rounded-lg mt-2 mb-5">
              <input
                className="border-2 border-gray-75 rounded-lg p-5 w-[245px] h-[50px]"
                type="text"
                defaultValue={referralUrl}
              />
              <button 
                className={
                  clsx(
                    `text-sm text-gray-350 w-[115px] right-1 top-1 m-1 rounded-md`, 
                    isCopied 
                    ? 'bg-grey-450 shadow-sm text-black' 
                    : 'bg-gradient-to-r from-cyan-650 to-cyan-75 text-white hover:bg-gradient-to-r hover:from-cyan-900 hover:to-cyan-650'
                  )
                }
              >
                {isCopied ? 'Скопійовано!' : 'Копіювати'}
              </button>
            </form>
            <div className="flex">
              <Link
                className="w-[50px] flex justify-center items-center rounded-full h-[50px] bg-gray-450 mr-6"
                to="/">
                <ImageShow
                  className="m-0"
                  image={{ url: 'email', label: 'email' }}
                />
              </Link>
              <Link
                className="w-[50px] flex justify-center items-center rounded-full h-[50px] bg-blue-250 mr-6"
                to="/">
                <ImageShow
                  className="m-0"
                  image={{ url: 'twitter', label: 'twitter' }}
                />
              </Link>
              <Link
                className="w-[50px] flex justify-center items-center rounded-full h-[50px] bg-blue-950 mr-6"
                to="/">
                <ImageShow
                  className="m-0"
                  image={{ url: 'facebook', label: 'facebook' }}
                />
              </Link>
              <Link
                className="w-[50px] flex justify-center items-center rounded-full h-[50px] bg-blue-350 mr-6"
                to="/">
                <ImageShow
                  className="m-0"
                  image={{ url: 'telegram', label: 'telegram' }}
                />
              </Link>
              <Link
                className="w-[50px] flex justify-center items-center rounded-full h-[50px] bg-purple-650 mr-6"
                to="/">
                <ImageShow
                  className="m-0"
                  image={{ url: 'viber', label: 'viber' }}
                />
              </Link>
            </div>
            <Accordion className="mt-10 block xl:hidden" items={items} />
            {/* <Link
              className="absolute bottom-0 text-cyan-650 underline text-sm"
              to="/referral-info">
              Бажаєте стати партнером?
            </Link> */}
          </div>
          <div className="xl:basis-1/2 border-l-[#f2f2f2] hidden xl:block border-l border-solid pl-10 py-2 pr-10">
            <h3 className="text-gray-350 pb-4 -ml-4 xs:whitespace-normal">
              Умови реферальної програми { mobileScreen && <br /> }  “Податкові друзі”
            </h3>
            {instructions}
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="page">
      <h1 className="text-black text-2xl xs:text-lg">Податкові друзі</h1>
      <p className="mb-20 xs:text-sm xs:mb-8">
        “Надай знижку товаришам у 20$ та отримай собі знижку 20$”
      </p>
      {content && content}
    </div>
  );
}

export default ReferralPage;
