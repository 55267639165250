import classNames from 'classnames';
import home from '../icons/home.svg';
import list from '../icons/list.svg';
import logout from '../icons/logout.svg';
import paper from '../icons/paper.svg';
import sinc from '../icons/sinc.svg';
import back from '../icons/back.svg';
import edit from '../icons/edit.svg';
import dots from '../icons/dots.svg';
import error from '../icons/error.svg';
import upload from '../icons/upload.svg';
import remove from '../icons/remove.svg';
import search from '../icons/search.svg';
import checked from '../icons/checked.svg';
import setting from '../icons/setting.svg';
import freedom from '../icons/freedomBroker.svg';
import interactive from '../icons/interactiveBroker.svg';
import arrowDown from '../icons/arrow-down.svg';
import arrowUp from '../icons/arrow-up.svg';
import arrowBottom from '../icons/arrow-bottom.svg';
import arrowLeft from '../icons/arrow-left.svg';
import arrowRight from '../icons/arrow-right.svg';
import referral from '../icons/referral.svg';
import folder from '../icons/folder.svg';
import video from '../icons/video.svg';
import videoWhite from '../icons/video-white.svg';
import download from '../icons/download.svg';
import checkedBig from '../icons/checked-big.svg';
import question from '../icons/question.svg';
import cardGray from '../icons/card-gray.svg';
import cardWhite from '../icons/card-white.svg';
import checkboxBlue from '../icons/checkbox-blue.svg';
import checkboxWhite from '../icons/checkbox-white.svg';
import checkedBlue from '../icons/checked-blue.svg';
import checkedWhite from '../icons/checked-white.svg';
import checkedBigWhite from '../icons/checked-big-white.svg';
import close from '../icons/close.svg';
import down from '../icons/down.svg';
import twitter from '../icons/twitter.svg';
import facebook from '../icons/facebook.svg';
import telegram from '../icons/telegram.svg';
import viber from '../icons/viber.svg';
import user from '../icons/user.svg';
import star from '../icons/star.svg';
import halfStar from '../icons/half-star.svg';
import email from '../icons/email.svg';
import envelope from '../icons/envelope.svg';
import paperPlane from '../icons/paper-plane.svg';

function ImageShow({ image, className }) {
  const classes = classNames(`inline-block ${!className && 'mr-2'}`, className);
  return (
    <img
      src={
        (image.url === 'home' && home) ||
        (image.url === 'list' && list) ||
        (image.url === 'logout' && logout) ||
        (image.url === 'paper' && paper) ||
        (image.url === 'setting' && setting) ||
        (image.url === 'upload' && upload) ||
        (image.url === 'sinc' && sinc) ||
        (image.url === 'freedom' && freedom) ||
        (image.url === 'interactive' && interactive) ||
        (image.url === 'back' && back) ||
        (image.url === 'remove' && remove) ||
        (image.url === 'checked' && checked) ||
        (image.url === 'error' && error) ||
        (image.url === 'edit' && edit) ||
        (image.url === 'search' && search) ||
        (image.url === 'dots' && dots) ||
        (image.url === 'arrow-down' && arrowDown) ||
        (image.url === 'arrow-up' && arrowUp) ||
        (image.url === 'arrow-bottom' && arrowBottom) ||
        (image.url === 'arrow-left' && arrowLeft) ||
        (image.url === 'arrow-right' && arrowRight) ||
        (image.url === 'referral' && referral) ||
        (image.url === 'folder' && folder) ||
        (image.url === 'video' && video) ||
        (image.url === 'video-white' && videoWhite) ||
        (image.url === 'download' && download) ||
        (image.url === 'checked-big' && checkedBig) ||
        (image.url === 'question' && question) ||
        (image.url === 'card-gray' && cardGray) ||
        (image.url === 'card-white' && cardWhite) ||
        (image.url === 'checkbox-blue' && checkboxBlue) ||
        (image.url === 'checkbox-white' && checkboxWhite) ||
        (image.url === 'checked-blue' && checkedBlue) ||
        (image.url === 'checked-white' && checkedWhite) ||
        (image.url === 'checked-big-white' && checkedBigWhite) ||
        (image.url === 'close' && close) ||
        (image.url === 'down' && down) ||
        (image.url === 'twitter' && twitter) ||
        (image.url === 'facebook' && facebook) ||
        (image.url === 'telegram' && telegram) ||
        (image.url === 'viber' && viber) ||
        (image.url === 'user' && user) ||
        (image.url === 'star' && star) ||
        (image.url === 'half-star' && halfStar) ||
        (image.url === 'email' && email) ||
        (image.url === 'envelope' && envelope) ||
        (image.url === 'paperPlane' && paperPlane)
      }
      className={classes}
      alt={image.label}
    />
  );
}

export default ImageShow;
