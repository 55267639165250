import { useState, useEffect, useRef } from "react";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import Panel from "./Panel";
import classNames from "classnames";

function Dropdown({
  options,
  value,
  onChange,
  children,
  dots,
  id,
  noborder,
  className,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const divEl = useRef();

  // when click on the second "select", the first opened one will be closed
  useEffect(() => {
    const handler = (event) => {
      if (!divEl.current) {
        return;
      }

      if (!divEl.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handler, true);

    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  const handleOptionClick = (option, id) => {
    setIsOpen(false);
    onChange(option, id);
  };

  const renderedOptions = options.map((option) => {
    const classes = `${
      option.disabled ? "pointer-events-none opacity-40" : ""
    } text-sm rounded cursor-pointer px-4 py-2
    hover:text-gray-350 hover:bg-gray-75 relative
    hover:rounded-tl-none hover:rounded-bl-none 
    hover:after:content-[''] hover:after:rounded-r-3xl
    hover:after:from-cyan-650 hover:after:to-cyan-75 
    hover:after:w-[3px] hover:after:py-3 hover:after:bg-gradient-to-r 
    hover:after:left-0 hover:after:top-0 hover:after:h-9 hover:after:absolute`;

    return (
      <div
        className={classes}
        onClick={() => handleOptionClick(option, id)}
        key={option.value}
        disabled={option.disabled}>
        {option.label}
      </div>
    );
  });

  const handleClick = () => {
    // setIsOpen(!isOpen);
    setIsOpen((currentIsOpen) => !currentIsOpen);
  };

  const panelClass = `flex  
    justify-between w-auto 
    items-center cursor-pointer ${
      noborder ? className : "text-sm text-gray-350 p-[9px]"
    }`;
  const panelClassDropdown = classNames(
    "absolute shadow p-0 w-auto",
    dots && "-top-[15px] -left-[110px]"
  );

  return (
    <div ref={divEl} className="relative">
      <Panel
        className={panelClass}
        onClick={handleClick}
        dots={dots}
        noborder={noborder}>
        {value?.label || children}
        {isOpen && !dots && <GoChevronUp className="text-lg text-gray-450" />}
        {!isOpen && !dots && (
          <GoChevronDown className="text-lg text-gray-450" />
        )}
      </Panel>
      {isOpen && (
        <Panel className={panelClassDropdown}>
          {isOpen && renderedOptions}
        </Panel>
      )}
    </div>
  );
}

export default Dropdown;
