import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import ImageShow from '../components/ImageShow';
import useNavigation from '../hooks/use-navigation';
import useToken from '../hooks/use-token';
import { useGetPersonalInfoQuery, useUpdatePersonalInfoMutation } from '../store/apis/userInfoApi';

function ProfilePage() {
	const { navigate } = useNavigation();

		return (
			<div className="page">
				<div className='flex gap-1 items-center mb-1'>
					<span onClick={() => navigate('/cabinet')}>
						<ImageShow
							image={{ url: 'back' }}
							className="mr-2 cursor-pointer"
							
						/>
					</span>
					<h1 className="text-black text-2xl">
						Особиста інформація
					</h1>
				</div>
				<p className="mb-10 text-gray-500">
					Використовується для наповнення податкової декларації 
				</p>
				<InfoForm />
			</div>
		);
};

const personalInfo = [
	{ label: "Прізвище, ім'я, по батькові (за наявності) платника податку", inputName: 'taxpayerFullName' },
	{ label: "Контактні телефони", inputName: 'contactPhoneNumber' },
	{ label: "Реєстраційний номер облікової картки платника податків", inputName: 'taxpayerIdentificationNumber' },
	{ label: "Електронна адреса", inputName: 'email' },
];

const addressInfo = [
	{ label: "Поштовий індекс", inputName: 'postalIndex' },
	{ label: "Область", inputName: 'region' },
	{ label: "Район", inputName: 'district' },
	{ label: "Місто (селище, село)", inputName: 'city' },
	{ label: "Вулиця", inputName: 'street' },
	{ label: "Корпус", inputName: 'block' },
	{ label: "Номер будинку", inputName: 'houseNumber' },
	{ label: "Номер квартири", inputName: 'apartmentNumber' },
];

function InfoForm() {
	const token = useToken();

	const [updatePersonalInfo, { status }] = useUpdatePersonalInfoMutation();
	const { data } = useGetPersonalInfoQuery(token);

	const [buttonText, setButtonText] = useState('Зберегти');

	const [formData, setFormData] = useState({
    taxpayerFullName: '',
    contactPhoneNumber: '',
    taxpayerIdentificationNumber: '',
    email: '',
    postalIndex: '',
    region: '',
    district: '',
    city: '',
    street: '',
    block: '',
    houseNumber: '',
    apartmentNumber: '',
  });

	const onSubmit = (e) => {
		e.preventDefault();
		if(status === 'loading') return;

		// const data = new FormData(e.target);
		// const formData = Object.fromEntries(data.entries());

		updatePersonalInfo({ token, ...formData });
	};

	const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

	const RenderItem = (label, inputName) => {
		return (
			<div className="flex gap-2 p-5 flex-1 bg-gray-200 rounded-2xl items-center lgdef:px-2">
				<label className="flex-1 text-sm min-w-[150px]"> { label } </label>
				<input 
					type="text" 
					className="border-none bg-white p-2 rounded-2xl flex-[2] lgdef:flex-[1.5] xl:flex-[2.5] h-12 focus:outline-none" 
					name={inputName} 
					value={formData[inputName]}
					onChange={handleChange}
				/>
			</div>
		)
	}

	useEffect(() => {
    if (data) {
      setFormData({
        ...formData,
				...data,
      });
    }
  }, [data]);

		useEffect(() => {
			if(status === 'fulfilled') {
				setButtonText('Збережено');
			}
		}, [status])
	
		useEffect(() => {
			if(buttonText === 'Збережено') {
				setTimeout(() => setButtonText('Зберегти'), 2000);
			}
		}, [buttonText]);

	return (
		<form id='personalInfo' className="flex flex-col gap-4" onSubmit={onSubmit}>
			<div className="grid gap-5">
				<div className="grid grid-cols-1 gap-1 items-stretch lgdef:grid-cols-2 lgdef:gap-5 xl:gap-10">
					<div className="flex flex-col gap-1 justify-center">
						{personalInfo.slice(0, 2).map((item) => RenderItem(item.label, item.inputName))}
					</div>
					<div className="flex flex-col gap-1 justify-center">
						{personalInfo.slice(-2).map((item) => RenderItem(item.label, item.inputName))}
					</div>
				</div>

				<div className="border-b border-grey-400" />
			
				<div className="grid grid-cols-1 gap-1 items-center lgdef:grid-cols-2 lgdef:gap-5 xl:gap-10">
					<div className="flex flex-col gap-1 justify-center">
						{addressInfo.slice(0, 4).map((item) => RenderItem(item.label, item.inputName))}
					</div>
					<div className="flex flex-col gap-1 justify-center">
						{addressInfo.slice(-4).map((item) => RenderItem(item.label, item.inputName))}
					</div>
				</div>
			</div>
			<button 
				className={
					clsx(
						`
							text-sm text-gray-350 mt-1 rounded-md py-2.5 px-4 w-fit
							bg-gradient-to-r from-cyan-650 to-cyan-75 text-white 
							hover:bg-gradient-to-r hover:from-cyan-900 hover:to-cyan-650
						`, 
					)
				}
				type="submit"
			>
				{ buttonText === 'Збережено' && <span className="mr-2">✓</span> }
				{ status === 'pending' ? 'Збереження...' : buttonText}
				{/* { status === 'loading' ? 'Збереження...' : 'Зберегти'} */}
			</button>
		</form>
	)
}

export default ProfilePage;