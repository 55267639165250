import { useAuth } from "react-oidc-context";
import Link from "./Link";

function Header() {
  const auth = useAuth();

  return (
    <div className="flex bg-gray-25 h-[60px] rounded-b-3xl flex items-center">
      <div className="font-roboto font-medium text-2xl ml-[5px] sm:ml-7 bg-clip-text text-transparent bg-gradient-to-r from-cyan-650 to-cyan-75">
        OSA.tax
      </div>
      <Link
        handleClick={() => void auth.removeUser()}
        to="/"
        className="ml-auto mr-[5px] sm:mr-7 block">
        <div
          className="flex justify-center text-md items-center text-base 
          font-medium rounded-full bg-gradient-to-r from-cyan-650 to-cyan-75 w-[30px] 
          h-[30px] text-white">
          {auth.user?.profile.given_name.slice(0, 1)}
        </div>
      </Link>
    </div>
  );
}

export default Header;
