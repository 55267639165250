import Modal from "../components/Modal";
import Link from "../components/Link";
import { useDispatch, useSelector } from "react-redux";
import { setShowModal, setBroker } from "../store";
import ImageShow from "../components/ImageShow";
import useBroker from "../hooks/use-broker";

function useModal() {
  const { broker } = useBroker();
  const dispatch = useDispatch();
  const showModal = useSelector((state) => {
    return state.modal.showModal;
  });

  const handleModalClick = (event) => {
    event.preventDefault();
    const currentBroker = event.target.getAttribute("broker");
    dispatch(setBroker(currentBroker));
    localStorage.setItem("broker", JSON.stringify(currentBroker));
    dispatch(setShowModal(true));
  };

  const handleClose = () => {
    dispatch(setShowModal(false));
  };

  const actionBar = (
    <div>
      <p className="text-sm pb-2 pt-3 text-gray-350">
        Виберіть спосіб імпортування даних
      </p>
      <div onClick={handleClose}>
        <Link
          to="/upload"
          className="text-black mb-1 rounded-2xl px-5 py-4 bg-zinc-50 rounded-lg w-full inline-block text-base mr-5">
          <div className="flex">
            <ImageShow
              className="mr-2"
              image={{ url: "upload", label: "Freedom" }}
            />
            <div className="inline-block">
              <p className="text-sm">Завантажити файли</p>
              <p className="text-xs text-gray-350">
                Вивантажити звіти брокера і завантажити вручну
              </p>
            </div>
          </div>
        </Link>
      </div>
      <div onClick={handleClose}>
        <Link
          onClick={handleClose}
          to="/accounts"
          className="text-black mb-1 rounded-2xl px-5 py-4 bg-zinc-50 rounded-lg w-full inline-block text-base mr-5">
          <div className="flex">
            <ImageShow
              className="mr-2"
              image={{ url: "sinc", label: "Interactive" }}
            />
            <div className="inline-block">
              <p className="text-sm">Автосинхронизация</p>
              <p className="text-xs text-gray-350">
                Синхронізуйте рахунок через API
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );

  const modal = (
    <Modal onClose={handleClose} actionBar={actionBar}>
      <div>
        <ImageShow
          className="h-[29px] mr-2"
          image={{ url: broker, label: broker }}
        />
        <span className="text-sm">Freedom Broker</span>
      </div>
    </Modal>
  );

  return {
    showModal,
    modal,
    handleModalClick,
  };
}

export default useModal;
