import React from 'react';
import ImageShow from '../components/ImageShow';
import Link from '../components/Link';
// import Chart from "chart.js/auto";
// import { Line } from "react-chartjs-2";

function Home() {
  // const data = {
  //   labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
  //   datasets: [
  //     {
  //       label: 'Freedom Broker',
  //       data: [33, 53, 85, 41, 44, 65],
  //       fill: true,
  //       backgroundColor: 'rgba(147,180,191,0.2)',
  //       borderColor: '#1A677B',
  //       tension: 0.5,
  //       pointStyle: 'circle',
  //       pointRadius: 6,
  //       pointHoverRadius: 10,
  //     },
  //     {
  //       label: 'InteractiveBrokers',
  //       data: [33, 25, 35, 51, 54, 76],
  //       fill: false,
  //       borderColor: '#742774',
  //       tension: 0.5,
  //       pointStyle: 'circle',
  //       pointRadius: 6,
  //       pointHoverRadius: 10,
  //     },
  //   ],
  // };
  return (
    <div className="page mt-[-16px] lg:mt-0 text-white bg-gradient-to-r from-cyan-650 to-cyan-75">
      <h1 className="text-2xl pb-14">Головна</h1>
      <h2 className="text-[32px] pb-14">
        Вітаю на платформі з підготовки податкових декларацій для інвесторів
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2">
        <div className="p-7 flex flex-col rounded-[30px] bg-gradient-to-r from-gray-250 to-gray-50">
          <h3 className="text-[18px] text-cyan-650 mb-8">РАХУНКИ</h3>
          <p className="text-sm text-gray-350">
            Розділ для завантаження брокерських звітів -{' '}
            <span className="font-bold">буде доступно 03.2024</span>
          </p>
        </div>
        <div className="p-7 flex flex-col rounded-[30px] bg-gradient-to-r from-gray-250 to-gray-50">
          <h3 className="text-[18px] text-cyan-650 mb-8">ПОДАТКОВІ ЗВІТИ </h3>
          <p className="text-sm text-gray-350">
            Для формування податкових декларацій -{' '}
            <span className="font-bold">буде доступно 03.2024</span>
          </p>
        </div>
        <div className="p-7 flex flex-col rounded-[30px] bg-gradient-to-r from-gray-250 to-gray-50">
          <h3 className="text-[18px] text-cyan-650 mb-8">ПОДАТКОВІ ДРУЗІ</h3>
          <p className="text-sm text-gray-350">
            Реферальна програма де ви можете запросити своїх друзів і отримати
            за це бонуси
          </p>
        </div>
        <div className="p-7 flex-col rounded-[30px] bg-gradient-to-r from-gray-250 to-gray-50">
          <div className="flex justify-between">
            <h3 className="text-[18px] text-cyan-650 mb-8">OSA-WIKI</h3>
            <a
              target="_blank"
              href="https://osa-tax.notion.site/WIKI-731de380af7e415d91bf9b6bd89c33b5?pvs=4"
              className="-mt-[5px] flex justify-center items-center w-[110px] h-[36px] mb-5 justify-center border-transparent rounded-xl text-white bg-gradient-to-r from-cyan-650 to-cyan-75">
              Відкрити
            </a>
          </div>
          <p className="text-sm text-gray-350">
            Наша “База Знань” де ви можете знайти відповіді на найпоширеніші
            питання
          </p>
        </div>
      </div>
      <p className="text-[16px] mt-12">Інші послуги:</p>
      <div className="flex text-[18px] mt-4">
        <div className="min-w-[520px] border-b border-white pb-2">
          Консультація з питань оподаткування
        </div>
        <div className="w-[100%] font-bold border-b border-white pb-2">
          50$/год
        </div>
      </div>
      <div className="flex text-[18px] mt-2">
        <div className="min-w-[520px] border-b border-white pb-2">
          Підготовка і надання відповіді на запит податкової
        </div>
        <div className="w-[100%] font-bold border-b border-white pb-2">50$</div>
      </div>
      <div className="flex text-[18px] mt-2">
        <div className="min-w-[520px] border-b border-white pb-2">
          Подання податкової декларації за 1 календарний рік
        </div>
        <div className="w-[100%] font-bold border-b border-white pb-2">25$</div>
      </div>
      <a
        target="_blank"
        href="https://docs.google.com/forms/d/e/1FAIpQLSf_08On8Dz36N2unPltGAJTGVm-C5WGyePd6-0yzb_nKwKozQ/viewform"
        className="flex text-[14px] mt-4 text-cyan-650 justify-center items-center w-[202px] h-[36px] mb-5 justify-center border-transparent rounded-xl bg-white">
        Скористатися послугами
      </a>
      <p className="text-[14px] mt-16 md:mt-72 mb-8 md:mb-0">
        <span className="block md:inline-block mb-2">
          Контакти для зв’язку з підтримкою:
        </span>
        <a className="md:ml-5" href="mailto:client.service@osa.tax">
          <ImageShow image={{ url: 'envelope', label: 'envelope' }} />
          client.service@osa.tax
        </a>
        <a className="ml-5" target="_blank" href="https://t.me/OSAtax">
          <ImageShow image={{ url: 'paperPlane', label: 'paperPlane' }} />
          @OSAtax
        </a>
      </p>
      {/* <div className="p-4 mt-2 w-full h-[400px] rounded-[30px] bg-gradient-to-r from-gray-250 to-gray-50">
        <Line
          data={data}
          width={"100%"}
          options={{ maintainAspectRatio: false }}
        />
      </div> */}
    </div>
  );
}

export default Home;
