import { useTaxDeclarationsYearQuery } from "../store";
import ImageShow from "../components/ImageShow";
import DownloadLink from "../components/DownloadLink";
import { GoSync } from "react-icons/go";
import { useEffect, useState } from "react";

function useTaxContent({ year, taxParams }) {
  const {
    data,
    error: errorTax,
    isFetching: isFetchingTax,
  } = useTaxDeclarationsYearQuery(taxParams);

  const [dataTax, setDataTax] = useState(null);

  useEffect(() => {
    if (data) {
      setDataTax(data.yearData.data);
    }
  }, [data]);

  const fileNames = [
    {
      name: "Декларація про майновий стан та доходи",
      document: "declaration",
      type: "xml",
    },
    { name: "Форма Ф1", document: "f1Form", type: "xml" },
    {
      name: "Супроводжуючий лист",
      document: "coverLetter",
      type: "vnd.openxmlformats-officedocument.wordprocessingml.document",
    },
  ];
  const filesTax = fileNames.map((file) => {
    let downloadFile = {};
    if (file.document === "declaration") {
      downloadFile = {
        link: data?.formData?.declaration?.data,
        label: "declaration",
        type: file.type,
      };
    } else if (file.document === "f1Form") {
      downloadFile = {
        link: data?.formData?.f1Form?.data,
        label: "f1-form",
        type: file.type,
      };
    } else if (file.document === "coverLetter") {
      downloadFile = {
        link: data?.formData?.coverLetter?.data,
        label: "cover-letter",
        type: file.type,
      };
    }

    return (
      <div
        key={file.name}
        className="flex bg-gray-25 py-5 pl-6 pr-4 border-b border-gray-75">
        <ImageShow
          className="mr-2 h-[24px] w-[18px]"
          image={{ url: "paper", label: "paper" }}
        />
        <p>{file.name}</p>
        <DownloadLink data={downloadFile} />
      </div>
    );
  });

  let contentTax;
  if (isFetchingTax) {
    contentTax = (
      <GoSync className="animate-spin inline-block text-green-700" />
    );
  } else if (errorTax) {
    console.error("Error loading data...");
  } else {
    contentTax = dataTax && (
      <div
        className="flex flex-wrap items-center border-gray-75 pb-2 
        sm:pb-0 bg-gray-75 rounded-2xl [&+div]:border-t-white">
        <div className="w-[85%] sm:w-auto order-1 flex py-5">
          <div className="ml-6 flex justify-center text-[28px] h-[36px] text-gray-350 ">
            <ImageShow image={{ url: "folder", label: "folder" }} />
          </div>
          <div className="flex items-center">
            <p>Декларації {dataTax.year}</p>
          </div>
        </div>
        <div className="order-3 sm:order-2 mr-8 text-sm ml-6 sm:ml-auto">
          <p className="text-xs text-gray-350">Прибуток</p>
          <span className="text-sm">{dataTax.profit}</span>
        </div>
        <div className="order-4 sm:order-3 mr-8">
          <p className="text-xs text-gray-350">Податок до сплати</p>
          <span className="text-sm">{dataTax.totalTax}</span>
        </div>
        <div className="order-2 sm:order-4 place-self-end self-center mr-5 ml-auto sm:ml-0 sm:mr-4">
          <DownloadLink data="" />
        </div>
        <div className="order-5 text-sm flex flex-col basis-full [&>*:last-child]:rounded-b-2xl">
          {filesTax}
        </div>
      </div>
    );
  }
  return {
    contentTax,
    isFetchingTax,
  };
}

export default useTaxContent;
