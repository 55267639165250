import forge from "node-forge";
// import { saveAs } from "file-saver";

export const createKeys = () => {
  return new Promise((resolve, reject) => {
    const rsa = forge.pki.rsa.generateKeyPair(
      { bits: 2048 },
      (err, keypair) => {
        if (err) {
          reject(err);
        } else {
          const publicKeyPem = forge.pki
            .publicKeyToPem(keypair.publicKey)
            .replace(
              /-----END PUBLIC KEY-----|-----BEGIN PUBLIC KEY-----|(\r\n|\n|\r)/gm,
              ""
            );
          const privateKeyPem = forge.pki.privateKeyToPem(keypair.privateKey);
          resolve({ publicKey: publicKeyPem, privateKey: privateKeyPem });
        }
      }
    );
  });
};

// export const createKeys = (callback) => {
//   forge.pki.rsa.generateKeyPair({ bits: 2048 }, (err, keypair) => {
//     if (err) {
//       callback(err, null);
//     } else {
//       const publicKeyPem = forge.pki
//         .publicKeyToPem(keypair.publicKey)
//         .replace(
//           /-----END PUBLIC KEY-----|-----BEGIN PUBLIC KEY-----|(\r\n|\n|\r)/gm,
//           ""
//         );
//       const privateKeyPem = forge.pki.privateKeyToPem(keypair.privateKey);
//       callback(null, { publicKey: publicKeyPem, privateKey: privateKeyPem });
//     }
//   });
// };

// export const createKeys = () => {
//   const rsaKeyPair = forge.pki.rsa.generateKeyPair(2048);
//   const privateKey = forge.pki.privateKeyToPem(rsaKeyPair.privateKey);
//   const publicKey = forge.pki
//     .publicKeyToPem(rsaKeyPair.publicKey)
//     .replace(
//       /-----END PUBLIC KEY-----|-----BEGIN PUBLIC KEY-----|(\r\n|\n|\r)/gm,
//       ""
//     );

//   return { privateKey, publicKey };
// };

export const decryptKeys = async (data, privateKeyPEM, file) => {
  const encryptedBytesSecretKey = forge.util.decode64(
    data.encryptedBase64SecretKey
  );
  const encryptedBytesInitializationVector = forge.util.decode64(
    data.encryptedBase64InitializationVector
  );
  const privateKey = forge.pki.privateKeyFromPem(privateKeyPEM);
  const decryptedSecretKey = privateKey.decrypt(
    encryptedBytesSecretKey,
    "RSA-OAEP",
    {
      md: forge.md.sha256.create(),
      mgf1: {
        md: forge.md.sha1.create(),
      },
    }
  );
  const decryptedInitializationVector = privateKey.decrypt(
    encryptedBytesInitializationVector,
    "RSA-OAEP",
    {
      md: forge.md.sha256.create(),
      mgf1: {
        md: forge.md.sha1.create(),
      },
    }
  );
  if (decryptedSecretKey && decryptedInitializationVector) {
  } else {
    console.error("Decryption failed");
  }

  const CryptoJS = require("crypto-js");
  const base64Key = decryptedSecretKey;
  const base64IV = decryptedInitializationVector;
  const key = CryptoJS.enc.Base64.parse(base64Key);
  const iv = CryptoJS.enc.Base64.parse(base64IV);

  const encryptFile = (file, key, iv) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const encryptedData = CryptoJS.AES.encrypt(e.target.result, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
          blockSize: 16,
        });

        resolve(encryptedData.toString());
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  };

  const result = await encryptFile(file, key, iv);
  const encryptedFile = new Blob([result], { type: "application/json" });

  // saveAs(encryptedFile);

  return { encryptedFile };
};
