import { useAuth } from "react-oidc-context";

function useToken() {
  const auth = useAuth();
  const token = auth.user?.access_token;

  return token;
}

export default useToken;
