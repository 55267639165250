import { useState } from "react";
import Dropdown from "../components/Dropdown";
import {
  useTaxDeclarationsInvestingAccountsQuery,
  setShowModal,
} from "../store";
import useToken from "../hooks/use-token";
import Reports from "../components/Reports";
import Link from "../components/Link";
import ImageShow from "../components/ImageShow";
import Checkout from "../components/Checkout";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../components/Modal";
import { GoSync } from "react-icons/go";
import useYearOptions from "../hooks/use-year-options";
import TaxContent from "../components/TaxContent";
import InvestAccountContent from "../components/InvestAccountContent";
import useTaxContent from "../hooks/use-tax-content";
import { setPay } from "../store";
import DownloadLink from "../components/DownloadLink";

function InfoPage() {
  const dispatch = useDispatch();
  const url = new URL(window.location.href);
  const paidParam = url.searchParams.get("paid");
  const yearParam = url.searchParams.get("year");

  if (paidParam) {
    dispatch(setPay(true));
  }
  let { year, options } = useYearOptions();
  if (yearParam) {
    year = yearParam;
  }

  const showModal = useSelector((state) => {
    return state.modal.showModal;
  });
  const paid = useSelector((state) => {
    return state.reports.paid;
  });

  const token = useToken();

  const [taxParams, setTaxParams] = useState({
    token,
    year: year,
  });
  const { contentTax, isFetchingTax } = useTaxContent({ year, taxParams });
  const {
    data: dataInvestAccount,
    error: errorInvestAccount,
    isFetching: isFetchingInvestAccount,
  } = useTaxDeclarationsInvestingAccountsQuery(taxParams);

  let contentInvestAccount;
  if (isFetchingInvestAccount) {
    contentInvestAccount = (
      <GoSync className="animate-spin inline-block text-green-700" />
    );
  } else if (errorInvestAccount) {
    console.error("Error loading data...");
  } else {
    contentInvestAccount = (
      <Reports
        data={dataInvestAccount?.investingAccountForTaxDeclarations}
        taxPage
      />
    );
  }

  const handleClose = () => {
    dispatch(setShowModal(false));
  };

  const [selection, setSelection] = useState({
    label: year,
    value: year,
  });

  const modal = (
    <Modal
      onClose={handleClose}
      actionBar={<Checkout year={selection.value} />}
      checkout></Modal>
  );

  const [showPaidModal, setShowPaidModal] = useState(paid);
  if (paid && showPaidModal) {
    setTimeout(() => {
      setShowPaidModal(false);
      dispatch(setPay(false));
      window.history.pushState({}, document.title, window.location.pathname);
    }, 4000);
  }

  const paidModal = (
    <Modal
      onClose={handleClose}
      actionBar={
        <>
          <ImageShow
            image={{ url: "checked-big-white", label: "checked big white" }}
          />
          <p className="mt-3 text-white">Оплата прошла успішно</p>
        </>
      }
      paid></Modal>
  );

  const handleSelect = (option) => {
    setSelection(option);
    setTaxParams({ token, year: option.value });
  };

  const allowCalculation =
    dataInvestAccount?.investingAccountForTaxDeclarations.find(
      (x) => x.hasPhantomOperations,
    );

  const fileNames = [
    { name: "Декларація про майновий стан та доходи" },
    { name: "Форма Ф1" },
    { name: "Супроводжуючий лист" },
  ];

  const filesTax = fileNames.map((file) => {
    return (
      <div
        key={file.name}
        className="flex py-5 pl-6 pr-4 border-b border-gray-75">
        <ImageShow
          className="mr-2 h-[24px] w-[18px]"
          image={{ url: "paper", label: "paper" }}
        />
        <p>{file.name}</p>
        <DownloadLink />
      </div>
    );
  });

  return (
    <>
      <div className="page">
        <h1 className="text-black inline-block text-lg leading-10 sm:leading-normal sm:text-2xl sm:pb-1">
          Податкові звіти
        </h1>
        <Link
          to="/declaration"
          className="float-right sm:float-none flex ml-auto w-[174px] rounded-xl h-22 bg-gradient-to-r p-[2px] from-cyan-650 to-cyan-75 place-self-end self-center">
          <div className="flex justify-between h-full bg-white text-white rounded-lg p-2">
            <ImageShow
              className="mr-2"
              image={{ url: "folder", label: "folder" }}
            />
            <span className="text-sm text-black">Готові декларації</span>
          </div>
        </Link>
        <div className="w-full sm:w-auto flex items-end flex-wrap sm:flex-nowrap mt-5 sm:mt-0">
          <p className="mr-2 text-gray-350 w-full sm:w-auto">
            Оберіть податковий період:
          </p>
          <Dropdown
            options={options}
            value={selection}
            onChange={handleSelect}
            noborder
            className="mt-1 sm:mt-0 text-2xl leading-7 bg-clip-text text-transparent bg-gradient-to-r from-cyan-650 to-cyan-75">
            <span>{year}</span>
          </Dropdown>
        </div>
        {!contentInvestAccount && !contentTax && (
          <div
            className="w-full items-center py-4 mt-7 sm:mt-16 min-h-[80px] 
          flex justify-left px-5 sm:px-7 border-transparent rounded-xl text-white 
          bg-gradient-to-r from-cyan-650 to-cyan-75">
            <p className="text-sm sm:text-normal">
              За цей рік Ви ще не імпортували жодного звіту. Для імпорту
              перейдіть на сторінку{" "}
              <Link to="/reports" className="underline">
                “Рахунки”
              </Link>
            </p>
          </div>
        )}
        {contentTax && (
          <TaxContent content={contentTax} isFetching={isFetchingTax} />
        )}
        {!contentTax && contentInvestAccount && (
          <InvestAccountContent
            content={contentInvestAccount}
            allowCalculation={allowCalculation}
          />
        )}
      </div>
      {showPaidModal && paidModal}
      {/* <div className="mx-[5px] sm:ml-[77px] sm:mr-[30px] pb-10">
            <h3 className="text-lg mt-20 mb-4">Готові документи:</h3>
            <div className="[&>:last-child]:border-none">{filesTax}</div>
            <Link
              to="/declaration"
              className="rounded-xl h-22 bg-gradient-to-r p-[2px] 
          from-cyan-650 to-cyan-75 flex w-[259px] mt-5">
              <div className="flex justify-between h-full bg-white text-white rounded-lg p-2">
                <ImageShow
                  className="mr-2"
                  image={{ url: "video", label: "video" }}
                />
                <span className="text-sm text-black">
                  Інструкція{" "}
                  <span className="hidden sm:inline">подачі декларації</span>
                </span>
              </div>
            </Link>
          </div> */}

      {showModal && modal}
    </>
  );
}

export default InfoPage;
