import React from "react";
import Link from "./Link";
import ImageShow from "./ImageShow";

const DownloadLink = ({ data }) => {
  const handleDownload = (event) => {
    event.preventDefault();
    if (data) {
      const blob = new Blob([data.link], { type: `application/${data.type}` });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = data.label;
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };

  return (
    <Link to="/" className="ml-auto" onClick={handleDownload}>
      <ImageShow
        className="mr-0"
        image={{ url: "download", label: "download" }}
      />
    </Link>
  );
};

export default DownloadLink;
