import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import Route from './components/Route';
import Button from './components/Button';
import HomePage from './pages/HomePage';
import AccountsPage from './pages/AccountsPage';
import TaxReportsPage from './pages/TaxReportsPage';
import SettingsPage from './pages/SettingsPage';
import UploadPage from './pages/UploadPage';
import ReportsPage from './pages/ReportsPage';
import LeftBar from './components/LeftBar';
import ReferralPage from './pages/ReferralPage';
import ReferralInfoPage from './pages/ReferralInfoPage';
import DeclarationPage from './pages/DeclarationPage';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import { useBecomeReferredMutation } from './store';
import useToken from './hooks/use-token';
import {
  ENABLE_INVESTING_ACCOUNT_CONTROLLER,
  ENABLE_TAX_DECLARATION_CONTROLLER,
  ENABLE_REFERRAL_CONTROLLER,
} from './constants/constants';

function App() {
  const auth = useAuth();
  const token = useToken();
  const [becomeReferred] = useBecomeReferredMutation();
  const url = new URL(window.location.href);
  const referral = url.searchParams.get('referral');
  const referralCode = JSON.parse(localStorage.getItem('referral'));
  if (referral && !referralCode) {
    localStorage.setItem('referral', JSON.stringify(referral));
  }

  useEffect(() => {
    if (referralCode && token) {
      becomeReferred({ token, referralCode }).then(() => {
        localStorage.removeItem('referral');
        window.location = '/';
      });
    }
  }, [becomeReferred, token, referralCode]);

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <div></div>; // Входимо...
    case 'signoutRedirect':
      return <div></div>; // Виходимо...
    default:
    // do nothing
  }

  if (auth.isLoading) {
    return <div></div>; // Завантажується...
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    // http://localhost:3000?referral=dd943074-5410-4264-b2ec-64646fee6d18
    // console.log("referral ", referral, auth.user?.profile?.sub);
    return (
      <div className="lg:mx-auto flex lg:min-h-[520px]">
        <LeftBar />
        <div className="w-full min-h-screen grid grid-rows-[auto_1fr_auto]">
          <div className="block lg:hidden z-[1]">
            <Header />
          </div>
          <Route path="/">
            <HomePage />
          </Route>
          {ENABLE_INVESTING_ACCOUNT_CONTROLLER && (
            <>
              <Route path="/accounts">
                <AccountsPage />
              </Route>
              <Route path="/upload">
                <UploadPage />
              </Route>
              <Route path="/reports">
                <ReportsPage />
              </Route>
            </>
          )}
          {ENABLE_TAX_DECLARATION_CONTROLLER && (
            <>
              <Route path="/declaration">
                <DeclarationPage />
              </Route>
              <Route path="/taxreports">
                <TaxReportsPage />
              </Route>
            </>
          )}
          {ENABLE_REFERRAL_CONTROLLER && (
            <>
              <Route path="/referral">
                <ReferralPage />
              </Route>
              <Route path="/referral-info">
                <ReferralInfoPage />
              </Route>
            </>
          )}
          <Route path="/settings">
            <SettingsPage />
          </Route>
          <div className="block lg:hidden">
            <Sidebar />
          </div>
        </div>
      </div>
    );
  }

  return void auth.signinRedirect();
}

export default App;
