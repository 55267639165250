import Sidebar from '../components/Sidebar';
import Link from '../components/Link';
import { useAuth } from 'react-oidc-context';
import ActiveLink from '../components/ActiveLink';

function LeftBar() {
  const auth = useAuth();

  const handleLogout = () => {
    auth.removeUser();
    auth.signoutRedirect();
  };
  return (
    <div className="hidden lg:block bg-zinc-50 relative w-[200px] flex-none bg-gray-25">
      <div className="font-roboto font-medium text-2xl pl-8 pt-7 bg-clip-text text-transparent bg-gradient-to-r from-cyan-650 to-cyan-75">
        OSA.tax
      </div>
      {/* <div className="text-xs text-gray-350 ml-8 mt-3 w-[148px] rounded  p-2 bg-white">
        <p>
          Вітаємо вас в <br />
          особистому кабінеті
        </p>
      </div> */}

      <Sidebar />
      <div className="absolute bottom-0 py-6">
        {/* <Link
          icon="setting"
          className="text-gray-350 text-sm block group pl-8 py-3"
          activeClassName={ACTIVE_CLASS}>
          Налаштування
          <ActiveLink />
        </Link> */}
        <Link
          icon="logout"
          handleClick={handleLogout}
          className="text-gray-350 text-sm pl-8 py-3 block hover:cursor-pointer">
          Вийти з аккаунту
        </Link>
        <div className="mt-6 text-gray-350 text-sm inline-block flex items-center pl-8">
          <span
            className="flex w-[34px] justify-center text-md items-center text-base 
          font-medium rounded-full bg-gradient-to-r from-cyan-650 to-cyan-75 w-[30px] 
          h-[30px] text-white">
            {auth.user?.profile.given_name.slice(0, 1)}
          </span>
          <p className="ml-2 leading-4 text-black font-medium">
            {auth.user?.profile.given_name} {auth.user?.profile.family_name}
          </p>
        </div>
      </div>
    </div>
  );
}

export default LeftBar;
