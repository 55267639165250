import { useState } from "react";
import ImageShow from "./ImageShow";
import Link from "./Link";
import classNames from "classnames";
import Button from "./Button";
import DownloadLink from "./DownloadLink";

function Accordion({ items, children }) {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleClick = (nextIndex) => {
    document
      .querySelector(`.block-${nextIndex}`)
      .classList.remove("animate-[out_1s_ease-in-out_forwards]");
    document
      .querySelector(`.block-${nextIndex}`)
      .classList.remove("group-hover:animate-[in_1s_ease-in-out_forwards]");
    document.querySelector(`.block-${nextIndex}`).classList.add("ml-5");
    setExpandedIndex((currentExpandedIndex) => {
      if (currentExpandedIndex === nextIndex) {
        return -1;
      } else {
        document
          .querySelectorAll(`div[class*='block']:not(.block-${nextIndex})`)
          .forEach((item) => {
            item.classList.remove("ml-5");
          });
        return nextIndex;
      }
    });
  };

  const handleMouseLeave = (index, isExpanded) => {
    document
      .querySelector(`.block-${index}`)
      .classList.remove("group-hover:animate-[in_1s_ease-in-out_forwards]");
    if (!isExpanded) {
      document.querySelector(`.block-${index}`).classList.remove("ml-5");
      document
        .querySelector(`.block-${index}`)
        .classList.add("animate-[out_1s_ease-in-out_forwards]");
    }
  };

  const handleMouseEnter = (index) => {
    document
      .querySelector(`.block-${index}`)
      .classList.add("group-hover:animate-[in_1s_ease-in-out_forwards]");
  };
  const renderedItems = items.map((item, index) => {
    const isExpanded = index === expandedIndex;
    const imagePath = isExpanded ? "up" : "down";
    const icon = (
      <ImageShow
        className="mr-0"
        image={{ url: `arrow-${imagePath}`, label: `arrow ${imagePath}` }}
      />
    );

    const files = item.content?.map((file) => {
      return (
        <div
          key={file.name}
          className="flex bg-gray-25 py-5 pl-6 pr-4 border-b border-gray-75">
          <ImageShow
            className="mr-2 h-[24px] w-[18px]"
            image={{ url: "paper", label: "paper" }}
          />
          <p>{file.name}</p>
          <DownloadLink />
        </div>
      );
    });

    const parentClasses = classNames(
      "flex flex-wrap items-center border-gray-75 pb-2 sm:pb-0",
      !isExpanded &&
        "hover:bg-gray-75 hover:rounded-2xl group [&:hover+div]:border-t-white",
      isExpanded && "bg-gray-75 rounded-2xl [&+div]:border-t-white",
    );

    const childClasses = classNames(
      "w-[85%] sm:w-auto order-1 flex py-5",
      `block-${index}`,
    );

    const buttonCasses = classNames(
      !isExpanded &&
        "transition-opacity duration-1000 ease-out opacity-0 group-hover:opacity-100 mr-2",
      isExpanded && "mr-2",
    );

    return (
      <div
        key={item.id}
        className={parentClasses}
        onMouseLeave={() => handleMouseLeave(index, isExpanded)}
        onMouseEnter={() => handleMouseEnter(index)}>
        <div className={childClasses}>
          <Button onClick={() => handleClick(index)} className={buttonCasses}>
            {icon}
          </Button>
          <div className="flex justify-center text-[28px] h-[36px] text-gray-350 ">
            <ImageShow image={{ url: "folder", label: "folder" }} />
          </div>
          <div className="flex items-center">
            <p> {item.label.name}</p>
          </div>
        </div>
        <div className="order-3 sm:order-2 mr-8 text-sm ml-6 sm:ml-auto">
          <p className="text-xs text-gray-350">Прибуток</p>
          <span className="text-sm">{item.label.income}</span>
        </div>
        <div className="order-4 sm:order-3 mr-8">
          <p className="text-xs text-gray-350">Податок до сплати</p>
          <span className="text-sm">{item.label.taxForPay}</span>
        </div>
        <div className="order-2 sm:order-4 place-self-end self-center mr-5 ml-auto sm:ml-0 sm:mr-4">
          <DownloadLink />
        </div>
        {isExpanded && (
          <div className="order-5 text-sm flex flex-col basis-full [&>*:last-child]:rounded-b-2xl">
            {files}
          </div>
        )}
      </div>
    );
  });
  return (
    <div className="w-full cursor-pointer [&>*:not(:first-child)]:border-t [&>*:last-child]:border-b">
      {renderedItems}
    </div>
  );
}

export default Accordion;
