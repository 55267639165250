export const env = {
ENABLE_INVESTING_ACCOUNT_CONTROLLER: "true",
ENABLE_TAX_DECLARATION_CONTROLLER: "true",
ENABLE_REFERRAL_CONTROLLER: "true",
ENABLE_PROFILE: "true",
KEYCLOAK_HOST: "https://auth.stg.osa.tax/realms/osatax-stg",
KEYCLOAK_CLIENT_ID: "oauth2-pkce-client",
BACKEND_HOST: "https://back.stg.osa.tax",
BANK: "albpay",
}
