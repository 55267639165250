import React, { useEffect } from "react";

const Fondy = ({ url }) => {
  useEffect(() => {
    const isIframe = document.querySelectorAll("#frameholder > iframe");
    if (isIframe.length > 0) {
      isIframe.forEach((e) => e.remove());
    }
    const fondyScript = document.createElement("script");
    fondyScript.src = "https://pay.fondy.eu/static_common/v1/checkout/ipsp.js";
    fondyScript.async = true;
    document.body.appendChild(fondyScript);
    fondyScript.onload = () => {
      if (window.$ipsp) {
        window.$ipsp
          .get("checkout")
          .config({
            wrapper: "#frameholder",
            styles: {
              body: { overflow: "hidden" },
              ".page-section-shopinfo": { display: "none" },
              ".page-section-footer": { display: "none" },
              "#submit_button": {
                color: "white",
                bottom: "70px",
                background: "linear-gradient(254deg, #93B4BF 0%, #1A677B 100%)",
                "border-radius": "10px",
              },
              ".gpay-button": { bottom: 0, "border-radius": "10px" },
              ".order-amount-total, .order-currency": { color: "#1A677B" },
              ".card #credit_card_number:active, .card #credit_card_number:focus, .card .credit_card_number:active, .card .credit_card_number:focus, .form-control.silver:active, .form-control.silver:focus, .form-control:active, .form-control:focus":
                {
                  background: "rgba(	147,	180,	191,.5)",
                  "border-color": "#1A677B",
                },
            },
          })
          .scope(function () {
            this.width(480);
            this.height(500);
            this.action("decline", function (data, type) {
              console.log(data);
            });
            this.action("message", function (data, type) {
              console.log(data);
            });
            this.loadUrl(url);
          });
      } else {
        console.error("Fondy script not loaded properly.");
      }
    };

    return () => {
      // Cleanup: Remove the script and styles when the component is unmounted
      document.body.removeChild(fondyScript);
    };
  }, [url]);

  return <div id="frameholder"></div>; // Changed the id to match the configuration
};

export default Fondy;
