import ImageShow from "../components/ImageShow";
import Link from "../components/Link";

function ReferralInfoPage() {
  return (
    <div className="page">
      <div className="flex items-center mb-2 sm:mb-6">
        <Link
          to="/referral"
          icon="back"
          className="text-gray-350 text-sm block"></Link>
        <h1 className="text-black text-lg sm:text-2xl">Партнерська програма</h1>
      </div>

      <div className="flex sm:items-center">
        <Link to="/referral" className="text-xs text-gray-350 hover:underline xs:text-lg">
          Податкові друзі |
        </Link>
        <span className="text-xs ml-1">Партнерська програма</span>
      </div>

      <h3 className="text-gray-350 mt-[60px] mb-4 xs:text-sm">
        Умови партнерської програми
      </h3>

      <ol className="list-decimal ml-5">
        <li>Earn commissions by promoting Koinly to your clients/audience.</li>
        <li>
          Please read the Koinly Affiliate Terms before continuing. Here is a
          brief summary of the main points:
        </li>
        <li>
          Base referral commission is 20% unless something else has been agreed
          with Koinly.
        </li>
        <li>
          Payouts can be requested through the Affiliate panel - there is a
          limit of 1 payout every 30 days and minimum payout amount is $100.
        </li>
        <li>
          Any commission earned is locked for a period of 30 days from the date
          you received it. This limit is in place to guard against
          refunds/chargebacks.
        </li>
        <li>
          Using any kind of paid advertising to promote your affiliate link is
          not allowed ex. google/bing ads, banner ads etc
        </li>
        <li>Spamming websites with your affiliate link is not allowed</li>
        <li>Sharing links on coupon or discount websites is not allowed</li>
        <li>
          Note: Any credits you have accrued as part of the refer-a-friend
          program will be lost if you proceed.
        </li>
        <li>Tell us how you plan on promoting Koinly</li>
      </ol>

      <Link
        to="/"
        onClick={(e) => e.preventDefault()}
        className="flex mt-7 rounded-lg bg-gradient-to-r w-[352px] h-[42px]
          from-cyan-650 to-cyan-75 items-center">
        <ImageShow
          className="mr-2 ml-3 w-[24px]"
          image={{ url: "user", label: "user" }}
        />
        <span className="text-white text-sm">
          Приєднатися до партнерської програми
        </span>
      </Link>
    </div>
  );
}

export default ReferralInfoPage;
