import React from "react";
import PanelBlock from "../components/PanelBlock";
import ImageShow from "../components/ImageShow";
import Link from "../components/Link";
import useModal from "../hooks/use-modal";

function Tax() {
  const { showModal, modal, handleModalClick } = useModal();

  const heading =
    "Ви ще не імпортували жодного звіту. Для імпорту виберіть брокера";

  return (
    <div className="page">
      <h1 className="text-black text-2xl pb-[90px]">Рахунки</h1>
      <PanelBlock heading={heading}>
        <Link
          handleClick={handleModalClick}
          to="/download"
          broker="freedom"
          className="text-black mb-5 rounded-2xl p-5 bg-white inline-block text-base mr-5">
          <ImageShow image={{ url: "freedom", label: "Freedom" }} />
          Freedom Broker
        </Link>
        <Link
          handleClick={handleModalClick}
          to="/download"
          broker="interactive"
          className="text-black rounded-2xl p-5 bg-white inline-block text-base">
          <ImageShow image={{ url: "interactive", label: "Interactive" }} />
          InteractiveBrokers
        </Link>
      </PanelBlock>
      <Link
        onClick={(e) => e.preventDefault()}
        to="/add-broker"
        className="underline underline-offset-2 text-gray-350 text-sm flex justify-end pt-2">
        Додати свого брокера
      </Link>
      {showModal && modal}
    </div>
  );
}

export default Tax;
