import { createSlice } from "@reduxjs/toolkit";

const filesSlice = createSlice({
  name: "file",
  initialState: {
    freedomFiles: [],
    interactiveFiles: [],
    broker: "",
    accountName: [],
  },
  reducers: {
    uploadFreedomFiles(state, action) {
      state.freedomFiles = action.payload;
    },
    uploadInteractiveFiles(state, action) {
      state.interactiveFiles = action.payload;
    },
    setBroker(state, action) {
      state.broker = action.payload;
    },
    setAccountName(state, action) {
      state.accountName = action.payload;
    },
  },
});

export const {
  uploadFreedomFiles,
  uploadInteractiveFiles,
  setBroker,
  setAccountName,
} = filesSlice.actions;
export const filesReducer = filesSlice.reducer;
