import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BACKEND_HOST } from "../../constants/constants";

const taxApi = createApi({
  reducerPath: "tax",
  baseQuery: fetchBaseQuery({
    baseUrl: BACKEND_HOST,
  }),
  endpoints(builder) {
    return {
      // Accounts Pages
      fetchMe: builder.query({
        query: (token) => {
          return {
            method: "GET",
            url: "/me",
            headers: {
              Authorization: `Bearer ${token.token}`,
            },
          };
        },
      }),
      createEncryptionKey: builder.mutation({
        query: (data) => {
          return {
            method: "POST",
            url: "/broker-reports/encryption/key",
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
            body: {
              rsaPublicKey: data.key,
            },
          };
        },
      }),
      sendEncryptedFile: builder.mutation({
        query: (data) => {
          const formData = new FormData();
          formData.append("file", data.file, data.fileName);
          return {
            method: "POST",
            url: `/broker-reports?brokerReportType=${data.type}&encryptionId=${data.id}`,
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
            body: formData,
          };
        },
      }),
      deleteFile: builder.mutation({
        invalidatesTags: (result, error, data) => {
          return [{ type: "SynchronizingFiles" }];
        },
        query: (data) => {
          return {
            method: "DELETE",
            url: `/broker-reports/${data.id}`,
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          };
        },
      }),
      synchronizeFiles: builder.mutation({
        invalidatesTags: (result, error, data) => {
          return [{ type: "SynchronizingFiles" }];
        },
        query: (data) => {
          return {
            method: "POST",
            url: "/investing-accounts/synchronize",
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
            body: {
              brokerReportIds: data.ids,
            },
          };
        },
      }),
      synchronizeFinal: builder.mutation({
        query: (data) => {
          return {
            method: "POST",
            url: "/investing-accounts/synchronize/final",
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
            body: {
              investingAccounts: data.accounts,
            },
          };
        },
      }),
      synchronizingFiles: builder.query({
        providesTags: (result, error, { id }) => [
          { type: "SynchronizingFiles", id },
        ],
        query: (data) => {
          return {
            method: "GET",
            url: "/investing-accounts/synchronizing",
            headers: {
              Authorization: `Bearer ${data.params.token}`,
            },
            params: {
              dataProvider: data.params.dataProvider,
            },
          };
        },
      }),
      synchronizedFiles: builder.query({
        providesTags: (result, error, { id }) => [
          { type: "SynchronizedFiles", id },
        ],
        query: (data) => {
          return {
            method: "GET",
            url: "/investing-accounts/synchronized",
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
            params: {
              dataProvider: data.dataProvider,
              page: data.page,
              size: data.size,
            },
          };
        },
      }),
      deleteAccount: builder.mutation({
        invalidatesTags: (result, error, data) => {
          return [
            { type: "SynchronizingFiles" },
            { type: "SynchronizedFiles" },
          ];
        },
        query: (data) => {
          return {
            method: "DELETE",
            url: `/investing-accounts/${data.id}`,
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          };
        },
      }),

      // Tax Reports Pages
      taxDeclarationsPayments: builder.mutation({
        query: (data) => {
          return {
            method: "POST",
            url: `/tax-declarations/${data.id}/payments?useBonuses=${data.bonusChecked}`,
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          };
        },
      }),
      taxDeclarationsCalculatePreliminary: builder.mutation({
        query: (data) => {
          return {
            method: "POST",
            url: `/tax-declarations/calculate-preliminary?year=${data.year}&useBonuses=${data.bonusChecked}`,
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          };
        },
      }),
      taxDeclarationsInvestingAccounts: builder.query({
        query: (data) => {
          return {
            method: "GET",
            url: "/tax-declarations/investing-accounts",
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
            params: {
              year: data.year,
            },
          };
        },
      }),
      taxDeclarationsYear: builder.query({
        async queryFn(data, _queryApi, _extraOptions, fetchWithBQ) {
          const yearData = await fetchWithBQ({
            method: "GET",
            url: `/tax-declarations/${data.year}`,
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          });
          let formData = {};
          if (!yearData.error) {
            formData["declaration"] = await fetchWithBQ({
              method: "GET",
              url: `/tax-declarations/${yearData.data.id}/declaration`,
              headers: {
                Authorization: `Bearer ${data.token}`,
              },
              responseHandler: (response) => response.text(),
            });
            formData["f1Form"] = await fetchWithBQ({
              method: "GET",
              url: `/tax-declarations/${yearData.data.id}/f1-form`,
              headers: {
                Authorization: `Bearer ${data.token}`,
              },
              responseHandler: (response) => response.text(),
            });
            formData["coverLetter"] = await fetchWithBQ({
              method: "GET",
              url: `/tax-declarations/${yearData.data.id}/cover-letter`,
              headers: {
                Authorization: `Bearer ${data.token}`,
              },
              responseHandler: (response) => response.text(),
            });
          }

          return { data: { yearData, formData } };
        },
      }),
      referralInfo: builder.query({
        query: (data) => {
          return {
            method: "GET",
            url: "/referral-info",
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          };
        },
      }),
      becomeReferred: builder.mutation({
        query: (data) => {
          return {
            method: "POST",
            url: `/become-referred?referralCode=${data.referralCode}`,
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          };
        },
      }),
    };
  },
});

export const {
  useFetchMeQuery,
  useCreateEncryptionKeyMutation,
  useSendEncryptedFileMutation,
  useDeleteFileMutation,
  useSynchronizeFilesMutation,
  useSynchronizingFilesQuery,
  useDeleteAccountMutation,
  useSynchronizeFinalMutation,
  useSynchronizedFilesQuery,

  useTaxDeclarationsPaymentsMutation,
  useTaxDeclarationsCalculatePreliminaryMutation,
  useTaxDeclarationsYearQuery,
  useTaxDeclarationsInvestingAccountsQuery,

  useReferralInfoQuery,
  useBecomeReferredMutation,
} = taxApi;
export { taxApi };
