import ImageShow from "../components/ImageShow";
import Link from "../components/Link";
import { GoSync, GoTrash } from "react-icons/go";

function File({ file, index, deleteFile, error }) {
  // <GoSync className="animate-spin inline-block text-green-700" />
  const icon = error ? (
    <ImageShow className="mr-3" image={{ url: "error", label: "Error" }} />
  ) : (
    <ImageShow className="mr-3" image={{ url: "checked", label: "Checked" }} />
  );

  return (
    <div className="flex flex-row rounded-2xl bg-white p-5 mb-1">
      {icon}
      <div className="flex flex-col w-full">
        <p className="text-sm break-all">{file?.name}</p>
        <p className="text-xs text-gray-350">{file?.size}</p>
      </div>
      <div className="w-[200px] self-center flex justify-end relative cursor-pointer">
        {error && (
          <>
            <Link
              className="text-sm h-[25px] text-gray-350 underline underline-offset-4"
              handleClick={(e) => e.preventDefault}>
              1 Помилка
              <div className="relative -top-5 h-[25px] justify-center opacity-0 hover:opacity-100 w-[85px]">
                <p className="absolute -top-10 text-sm border-white shadow-md min-w-[184px] ml-20 rounded-xl bg-white p-4 pointer-events-none">
                  {error?.data?.commonMessage}
                </p>
              </div>
            </Link>
          </>
        )}
        <button
          className="place-self-end self-center"
          onClick={() => deleteFile(index)}>
          <ImageShow image={{ url: "remove", label: "Remove" }} />
        </button>
      </div>
    </div>
  );
}

export default File;
