import { useState } from "react";
import ImageShow from "./ImageShow";

function Accordion({ items, className }) {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleClick = (nextIndex) => {
    setExpandedIndex((currentExpandedIndex) => {
      if (currentExpandedIndex === nextIndex) {
        return -1;
      } else {
        return nextIndex;
      }
    });
  };

  const renderedItems = items.map((item, index) => {
    const isExpanded = index === expandedIndex;
    const icon = (
      <span className="text-2xl">
        {isExpanded ? (
          <ImageShow image={{ url: "arrow-up", label: "arrow up" }} />
        ) : (
          <ImageShow image={{ url: "arrow-down", label: "arrow down" }} />
        )}
      </span>
    );

    const labelClasses = `flex mt-4 items-center cursor-pointer ${
      !isExpanded && "border-b"
    }`;

    return (
      <div key={item.id}>
        <div onClick={() => handleClick(index)} className={labelClasses}>
          {icon}
          <span className="text-gray-350 text-sm xs:whitespace-normal">{item.label}</span>
        </div>
        {isExpanded && <div className="px-5 py-4 border-b">{item.content}</div>}
      </div>
    );
  });
  return <div className={className}>{renderedItems}</div>;
}

export default Accordion;
