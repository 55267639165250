import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { taxApi } from "./apis/taxApi";

import { modalReducer, setShowModal } from "./slices/modalSlice";
import {
  filesReducer,
  uploadFreedomFiles,
  uploadInteractiveFiles,
  setBroker,
  setAccountName,
} from "./slices/filesSlice";
import { setPay, reportsReducer } from "./slices/reportsSlice";

const store = configureStore({
  reducer: {
    modal: modalReducer,
    file: filesReducer,
    reports: reportsReducer,
    [taxApi.reducerPath]: taxApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(taxApi.middleware);
  },
});

setupListeners(store.dispatch);

export {
  useFetchMeQuery,
  useCreateEncryptionKeyMutation,
  useSendEncryptedFileMutation,
  useDeleteFileMutation,
  useSynchronizeFilesMutation,
  useSynchronizingFilesQuery,
  useDeleteAccountMutation,
  useSynchronizeFinalMutation,
  useSynchronizedFilesQuery,
  useTaxDeclarationsPaymentsMutation,
  useTaxDeclarationsCalculatePreliminaryMutation,
  useTaxDeclarationsYearQuery,
  useTaxDeclarationsInvestingAccountsQuery,
  useReferralInfoQuery,
  useBecomeReferredMutation,
} from "./apis/taxApi";

export {
  store,
  setShowModal,
  uploadFreedomFiles,
  uploadInteractiveFiles,
  setBroker,
  setAccountName,
  taxApi,
  setPay,
};
