import ImageShow from "./ImageShow";
import Dropdown from "./Dropdown";
import { FREEDOM_TYPE, INTERACTIVE_TYPE } from "../constants/constants";
import useDate from "../hooks/use-date";
import { useState } from "react";
import { useDeleteAccountMutation } from "../store";
import useToken from "../hooks/use-token";
import Link from "./Link";

function Reports({ data, taxPage }) {
  const [deleteAccount, results] = useDeleteAccountMutation();
  const { getDate } = useDate();
  const token = useToken();
  const [selection] = useState(null);
  const accountOptions = [
    { label: "Видалити", value: "delete" },
    { label: "Архівувати", value: "archive", disabled: true },
  ];

  const handleSelectDots = (option, id) => {
    if (option.value === "delete") {
      deleteAccount({
        token,
        id: id,
      });
    }
  };

  if (results.status === "fulfilled" && data?.length === 0) {
    window.location = "/accounts";
  }

  const handleMouseLeave = (event) => {
    const targetElement = event.target;
    if (targetElement?.children[0]) {
      targetElement.children[0].classList.add(
        "animate-[out_1s_ease-in-out_forwards]",
      );
    }
  };

  const content = data?.map((account) => {
    let broker;
    if (account.dataProvider === FREEDOM_TYPE) {
      broker = "freedom";
    } else if (account.dataProvider === INTERACTIVE_TYPE) {
      broker = "interactive";
    }
    return (
      <div
        onMouseLeave={handleMouseLeave}
        key={account.id}
        className="flex items-center sm:h-[90px] border-gray-75
        hover:bg-gray-75 hover:rounded-2xl group
        [&:hover+div]:border-t-white relative
        flex-wrap sm:flex-nowrap justify-between sm:justify-stretch pb-2 sm:p-0">
        <div
          className="order-1 w-[90%] py-5 sm:py-0
        flex group-hover:animate-[in_1s_ease-in-out_forwards]">
          <div className="flex justify-center min-w-[75px] text-gray-350 ">
            <ImageShow
              className="mr-0 h-[50px]"
              image={{ url: broker, label: broker }}
            />
          </div>
          <div className="flex flex-col justify-center">
            <p className="text-sm leading-[14px]"> {account.name}</p>
            <span className="text-xs text-gray-350">{account.originalId}</span>
          </div>
        </div>
        <div className="order-3 sm:order-2 ml-3 sm:mr-8 sm:ml-auto text-sm">
          <p className="text-xs text-gray-350">Транзакцій</p>
          <span className="text-sm">
            {account.transactionCount ? account.transactionCount : 0}
          </span>
        </div>
        <div className="order-4 sm:order-3 sm:mr-8">
          <p className="text-xs text-gray-350 whitespace-nowrap">
            Дата створення
          </p>
          <span className="text-sm">{getDate(account.createDateTime)}</span>
        </div>
        <div className="order-5 sm:order-4 mr-4 sm:mr-8 xl:mr-36">
          <p className="text-xs text-gray-350">Дата змін</p>
          <span className="text-sm">
            {getDate(account.lastModifiedDateTime)}
          </span>
        </div>
        {account.hasPhantomOperations && (
          <Link
            className="absolute right-3 top-7 z-10 w-[36px]"
            handleClick={(e) => e.preventDefault}>
            <ImageShow
              className="w-[36px]"
              image={{ url: "error", label: "error" }}
            />
            <div className="relative -top-5 h-[36px] justify-center opacity-0 hover:opacity-100 w-[36px]">
              <p className="absolute -top-11 text-sm border-white shadow-md -left-[200px] w-[184px] rounded-xl bg-white p-4 pointer-events-none">
                Недостатноь даних. Завантажте данні за попередні роки.
              </p>
            </div>
          </Link>
        )}
        {!taxPage && (
          <div className="order-2 sm:order-5 place-self-end self-center mr-1 sm:mr-4">
            <Dropdown
              options={accountOptions}
              value={selection}
              onChange={handleSelectDots}
              dots
              id={account.id}>
              <span className="">
                <ImageShow image={{ url: "dots", label: "Dots" }} />
              </span>
            </Dropdown>
          </div>
        )}
      </div>
    );
  });
  return content;
}

export default Reports;
