const useYearOptions = () => {
  const year = new Date().getFullYear() - 1;
  let options = [{ label: year, value: year }];

  for (let i = 1; i < 20; i++) {
    const prevYear = year - i;
    if (prevYear === 2017) {
      break;
    } else {
      options.push({ label: prevYear, value: prevYear });
    }
  }

  return { year, options };
};

export default useYearOptions;
