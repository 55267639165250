import ReactDOM from "react-dom";
import { useEffect } from "react";
import Button from "./Button";
import ImageShow from "./ImageShow";

function Modal({ onClose, children, actionBar, checkout, paid }) {
  useEffect(() => {
    document.body.classList.add("overflow-hidden");

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  return ReactDOM.createPortal(
    <div>
      <div
        onClick={onClose}
        className="fixed inset-0 bg-gray-300 opacity-80"></div>
      {!checkout && !paid && (
        <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[437px] h-[290px]">
          <div className="rounded-t-2xl flex justify-between bg-zinc-50 p-5">
            {children}
            <Button className="ml-auto" onClick={onClose}>
              <ImageShow image={{ url: "close", label: "close" }} />
            </Button>
          </div>
          <div className="rounded-b-2xl bg-white p-5">{actionBar}</div>
        </div>
      )}
      {checkout && (
        <div className="fixed top-5 w-full h-full overflow-x-hidden">
          <Button className="ml-auto mb-5" onClick={onClose}>
            <ImageShow image={{ url: "close", label: "close" }} />
          </Button>
          {actionBar}
        </div>
      )}
      {paid && (
        <div className="flex flex-col justify-center items-center w-[360px] h-[220px] bg-gradient-to-r from-cyan-650 to-cyan-75 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-[32px]">
          {actionBar}
        </div>
      )}
    </div>,
    document.querySelector(".modal-container"),
  );
}

export default Modal;
