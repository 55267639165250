import Link from './Link';
import { useSynchronizedFilesQuery } from '../store';
import useToken from '../hooks/use-token';
import ActiveLink from '../components/ActiveLink';
import {
  ACTIVE_CLASS,
  ENABLE_BROKER_REPORT_CONTROLLER,
  ENABLE_INVESTING_ACCOUNT_CONTROLLER,
  ENABLE_TAX_DECLARATION_CONTROLLER,
  ENABLE_REFERRAL_CONTROLLER,
} from '../constants/constants';

function Sidebar() {
  const token = useToken();
  let params = {
    token,
    dataProvider: '',
    page: 0,
    size: 1,
  };
  const { data } = useSynchronizedFilesQuery(params);

  let accountLink = '/accounts';
  if (data?.content?.length > 0) {
    accountLink = '/reports';
  }

  const links = [{ label: 'Головна', path: '/', icon: 'home' }];
  ENABLE_INVESTING_ACCOUNT_CONTROLLER &&
    links.push({
      label: 'Рахунки',
      path: accountLink,
      icon: 'list',
    });
  ENABLE_TAX_DECLARATION_CONTROLLER &&
    links.push({
      label: 'Податкові звіти',
      path: '/taxreports',
      icon: 'paper',
    });
  ENABLE_REFERRAL_CONTROLLER &&
    links.push({
      label: 'Податкові друзі',
      path: '/referral',
      icon: 'referral',
    });

  const renderedLinks = links.map((link) => {
    return (
      <Link
        key={link.label}
        to={link.path}
        className="mx-[20px] lg:mx-0 flex-wrap flex justify-center lg:justify-normal lg:flex-none lg:h-auto h-[60px] lg:pb-3 text-sm w-[40px] lg:w-auto lg:pl-8 lg:py-3 text-gray-350 group"
        activeClassName={ACTIVE_CLASS}
        icon={link.icon}
        iconClass="lg:mr-2 mr-0">
        <span className="hidden lg:inline-block">{link.label}</span>
        <ActiveLink />
      </Link>
    );
  });

  return (
    <div
      className="mt-[-16px] lg:mt-6 flex lg:flex-col justify-center 
    bg-gray-25 lg:bg-transparent lg:justify-normal
    lg:rounded-t-0 rounded-t-3xl items-center lg:items-stretch">
      {renderedLinks}
    </div>
  );
}

export default Sidebar;
