import React from 'react';

function SettingsPage() {
    return (
        <div className="page">
			<h1 className="text-black text-2xl pb-[90px]">Налаштування</h1>
        </div>
    );
};

export default SettingsPage;