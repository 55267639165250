import { env } from "./env-config.js";

export const FREEDOM_TYPE = "FREEDOM_FINANCE";
export const INTERACTIVE_TYPE = "INTERACTIVE_BROKERS";
export const ACTIVE_CLASS = `
  is-active bg-white relative
  after:content-[''] 
  after:from-cyan-650 after:to-cyan-75 
  after:bg-gradient-to-r 
  after:left-0 after:absolute

  after:rounded-tl-3xl after:h-[3px]
  after:w-[40px] after:top-auto after:bottom-0

  lg:after:rounded-t-none lg:after:rounded-tr-3xl
  lg:after:rounded-r-3xl lg:after:bottom-auto
  lg:after:w-[3px] lg:after:h-11
  lg:after:top-0 lg:after:py-3
`;

export const ENABLE_INVESTING_ACCOUNT_CONTROLLER =
  env.ENABLE_INVESTING_ACCOUNT_CONTROLLER === "true";
export const ENABLE_TAX_DECLARATION_CONTROLLER =
  env.ENABLE_TAX_DECLARATION_CONTROLLER === "true";
export const ENABLE_REFERRAL_CONTROLLER =
  env.ENABLE_REFERRAL_CONTROLLER === "true";
export const KEYCLOAK_HOST = env.KEYCLOAK_HOST;
export const KEYCLOAK_CLIENT_ID = env.KEYCLOAK_CLIENT_ID;
export const BACKEND_HOST = env.BACKEND_HOST;

// docker build -t osa-web -f ./web.Dockerfile ./
// docker run -p 3000:3000 -e ENABLE_INVESTING_ACCOUNT_CONTROLLER=false -e ENABLE_TAX_DECLARATION_CONTROLLER=false -e ENABLE_REFERRAL_CONTROLLER=true -e KEYCLOAK_HOST=https://monkfish-app-w2o9y.ondigitalocean.app/realms/osa-test-realm -e KEYCLOAK_CLIENT_ID=oauth2-pkce-client -e BACKEND_HOST=https://king-prawn-app-rbn5l.ondigitalocean.app osa-web
