import classNames from "classnames";

function Panel({ children, className, dots, noborder, ...rest }) {
  const border = dots || noborder ? "" : "border rounded bg-white z-10";
  const finalClassNames = classNames(border, className);

  return (
    <div {...rest} className={finalClassNames}>
      {children}
    </div>
  );
}

export default Panel;
