function ActiveLink() {
  return (
    <>
      <span
        className="hidden group-[.is-active]:block
        before:content-[''] before:absolute
        before:absolute before:w-5 before:h-5 
        before:bg-white 
        before:-left-5
        lg:before:-top-[20px] 
        lg:before:-right-0 
        lg:before:left-auto

        after:content-[''] after:absolute
        after:absolute after:w-5 after:h-5 after:bg-gray-25 
        after:rounded-tr-3xl
        after:-left-5
        lg:after:rounded-tr-none
        lg:after:left-auto
        lg:after:-top-[20px]
        lg:after:-right-0
        lg:after:rounded-br-3xl"></span>
      <span
        className="hidden group-[.is-active]:block
        before:content-[''] before:absolute
        before:absolute before:w-5 before:h-5 before:bg-white 

        before:bottom-auto
        before:-right-5
        lg:before:-bottom-[20px]
        lg:before:-right-0

        after:content-[''] after:absolute
        after:absolute after:w-5 after:h-5 after:bg-gray-25 

        after:bottom-atuo
        after:-right-5
        after:rounded-tl-3xl
        lg:after:-bottom-[20px]
        lg:after:-right-0
        lg:after:rounded-tl-none
        lg:after:rounded-tr-3xl"></span>
    </>
  );
}

export default ActiveLink;
