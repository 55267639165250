const useDate = () => {
  const getDate = (currentDate) => {
    // const date = new Date(currentDate);
    // const year = date.getFullYear();
    // const month = date.getMonth() + 1;
    // const day = date.getDate();
    // const formatedDate = [day, month, year].join(".");

    // return formatedDate;

    const date = new Date(currentDate);
    const result = date.toLocaleDateString("fr-CH", {
      // you can use undefined as first argument
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    return result;
  };

  return { getDate };
};

export default useDate;
