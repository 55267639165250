import { createSlice } from "@reduxjs/toolkit";

const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    paid: false,
  },
  reducers: {
    setPay(state, action) {
      state.paid = action.payload;
    },
  },
});

export const { setPay } = reportsSlice.actions;
export const reportsReducer = reportsSlice.reducer;
