import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import Dropdown from '../components/Dropdown';
import ImageShow from '../components/ImageShow';
import {Tooltip} from '../components/Tooltip';
import useNavigation from '../hooks/use-navigation';
import useToken from '../hooks/use-token';
import useYearOptions from '../hooks/use-year-options';
import {useGetIncomesInfoQuery, useUpdateIncomesInfoMutation} from '../store/apis/userInfoApi';
import {tooltip} from "@material-tailwind/react";

function IncomesPage() {
    const {navigate} = useNavigation();

    let {year, options} = useYearOptions();

    const [selection, setSelection] = useState({
        label: year,
        value: year,
    });

    const handleSelect = (option) => {
        setSelection(option);
    };

    return (
        <div className="page">
            <div className='flex gap-1 items-center mb-1'>
				<span onClick={() => navigate('/cabinet')}>
					<ImageShow
                        image={{url: 'back'}}
                        className="mr-2 cursor-pointer"

                    />
				</span>
                <h1 className="text-black text-2xl">
                    Інші доходи
                </h1>
            </div>
            <p className="mb-4 text-gray-500">
                Містить інформацію про доходи, не пов’язані з інвестиціями на фондових біржах.
            </p>
            <div className="w-full sm:w-auto flex items-end flex-wrap sm:flex-nowrap mt-5 sm:mt-0 mb-10">
                <p className="mr-2 text-gray-350 w-full sm:w-auto">
                    Оберіть податковий період:
                </p>
                <Dropdown
                    options={options}
                    value={selection}
                    onChange={handleSelect}
                    noborder
                    className="mt-1 sm:mt-0 text-2xl leading-7 bg-clip-text text-transparent bg-gradient-to-r from-cyan-650 to-cyan-75">
                    <span>{year}</span>
                </Dropdown>
            </div>
            <InfoForm year={selection.value}/>
        </div>
    );
};

const personalInfo = [
    {
        label: "Дохід з зарплати за договором (10.1)",
        code: 'code_10_1',
        tooltip: "Враховуються усі доходи, які ви отримали як зарплату, бонуси або компенсації згідно з трудовим договором."
    },
    {
        label: "Винагороди ЦПД (10.2)",
        code: 'code_10_2',
        tooltip: "Договори ЦВХ, гонорари, роялті, і т.д."
    },
    {
        label: "Заробітня плата резидента “Дія Сіті” (10.3)",
        code: 'code_10_3',
        tooltip: "Доходи отримані вами як резидентом \"Дія Сіті\" (зарплата, гіг-контракт)"
    },
    {
        label: "Дивіденди отримані від українських компаній в Україні (10.4)",
        code: 'code_10_4',
        tooltip: "Дивіденди, окрім тих, що нараховані нерезидентами чи виключені з оподатковуваного доходу"
    },
    {
        label: "Дохід з продажу рухомого/нерухомого майна (10.5)",
        code: 'code_10_5',
        tooltip: "Продаж майна. Нерухомого: протягом часу менше ніж 3 роки володіння. Рухомого: авто та інше 2й та більше раз за рік"
    },
    {
        label: "Дохід з оренди майна (10.6)",
        code: 'code_10_6',
        tooltip: "Оренда майна у т.ч. дохід від надання земельної частки (паю) в лізинг, оренду або суборенду"
    },
    {
        label: "Дохід з оренди майна, де орендарем є фізична особі, яка не є податковим агентом (10.6.1)",
        code: 'code_10_6_1',
        tooltip: "Дохід від надання земельної частки (паю) в лізинг, оренду або суборенду, де орендарем є фізична особа, яка не є податковим агентом"
    },
    {
        label: "Дохід з продажу сільськогосподарської продукції (10.7)",
        code: 'code_10_7',
        tooltip: "Доходи від реалізації вирощеної або обробленої продукції на власній землі"
    },
    {
        label: "Вартість успадкованого чи подарованого майна (10.9)",
        code: 'code_10_9',
        tooltip: "Подарунки в сумі понад 25% МЗП або спадщина від осіб, які не є родичами 1-2го ступеню рідства, або родичів - нерезидентів 1-2го ступеню "
    },
    {
        label: "Дохід ФОП від провадження господарської діяльності по загальній системі (10.11)",
        code: 'code_10_11',
        tooltip: "Дохід ФОП на загальній системі оподаткування (має співпадати з декларацією ФОПа)"
    },
    {
        label: "Професійний дохід (10.12)",
        code: 'code_10_12',
        tooltip: "Дохід аудиторів, адвокатів, нотаріусів і т.і. незалежних експертів (і має співпадати з раніше поданою декларацією)"
    },
    {
        label: "Інші доходи (10.13)",
        code: 'code_10_13',
        tooltip: "Усі види доходів які не були вказані в інших пунктах, наприклад:" +
            " кешбек банків;" +
            " відсотки по корпоративним облігаціям;" +
            " безоплатно отримані послуги (страхування, спортзали, басейни, і т.д.) від роботодавця;" +
            " списані борги, пені, штрафи, і т.д.;" +
            " нецільова благодійна допомога поза лімітом"
    },
    {
        label: "Прибуток КІК (10.14)",
        code: 'code_10_14',
        tooltip: "Прибуток юридичної особи КІК який має право управління від 50% або володіє часткою в статутному капіталі. Той хто має право власності від 25% якщо її загальна частка в групі компаній від 50%."
    },
    {
        label: "Прибуток партнерств КІК (10.15)",
        code: 'code_10_15',
        tooltip: "Прибуток з трасту (відповідно до законодавства про КІК)"
    },
];

const incomesInfo = [
    {
        label: "Дохід ФОП від провадження господарської діяльності по спрощеній системі (11.1)",
        inputName: 'code_11_1',
        tooltip: "ФОП на єдиному податку (має співпадати з декларацією ФОПа)"
    },
    {
        label: "Дохід з продажу рухомого/нерухомого майна яке не оподатковується (11.2)",
        inputName: 'code_11_2',
        tooltip: "Доходи від продаж майна або авто звільнених від податку"
    },
    {
        label: "Інші неоподатковувані доходи (11.3)",
        inputName: 'code_11_3',
        tooltip: "Інші доходи, що не підлягають оподаткуванню: ОВДП, євробонди України, спадщина/стипендії/суми страхових виплат/подарунки, спадщина від родичів 1-2 ступеню рідства (окрім спадщини отриманої від них, як від нерезидентів - рядок 10.9 або отриманих за кордоном - рядок 10.10, пенсії, соц.виплати у вигляді допомоги при народженні дитини, державної допомоги малозабезпеченим сім’ям, виплат особам з інвалідністю, компенсацій за шкоду заподіяну здоров’ю."
    },
];

const options = [
    {label: 'Квартири', value: 'APARTMENTS'},
    {label: 'Житлові будинки', value: 'RESIDENTIAL_BUILDINGS'},
    {label: 'Автомобілі легкові', value: 'PASSENGER_CARS'},
    {label: 'Автомобілі вантажні (спеціальні)', value: 'TRUCKS'},
    {label: 'Мотоцикли (мопеди)', value: 'MOTORCYCLES'},
    {label: "Гаражі", value: 'GARAGES'},
    {label: 'Садові (дачні) будинки', value: 'GARDEN_HOUSES'},
    {label: 'Земельні ділянки (крім ділянок сільськогосподарських угідь)', value: 'LAND_PLOTS'},
    {label: 'Мотоцикли', value: 'PLOTS_OF_AGRICULTURAL_LAND'},
    {label: 'Земельні ділянки, віднесені до сільськогосподарських угідь', value: 'RESERVOIRS'},
    {label: 'Водні транспортні засоби', value: 'WATER_VEHICLES'},
    {label: 'Повітряні судна', value: 'AIRCRAFT'},
    {label: 'Інше нерухоме (рухоме) майно', value: 'OTHER_IMMOVABLE_OR_MOVABLE_PROPERTY'},
];

const personalName = 'incomeIncludedInTotalAnnualTaxableIncome';
const incomesName = 'incomeNotIncludedInTotalAnnualTaxableIncome';

function InfoForm({year}) {
    const token = useToken();
    const [rows, setRows] = useState([]);
    const [values, setValues] = useState({});

    const {data, error} = useGetIncomesInfoQuery({token, year});
    const [updateIncomesInfo, {status}] = useUpdateIncomesInfoMutation();

    const [errors, setErrors] = useState({});
    const [buttonText, setButtonText] = useState('Зберегти');

    const handlePropertySelect = (ind) => (option) => {
        setRows(rows.map((row, index) => {
            if (index === ind) {
                return {...row, categoriesOfObjects: option};
            }
            return row;
        }));
    };

    const onPropertyCheckboxChange = (ind) => (e) => {
        const {checked} = e.target;

        setRows(rows.map((row, index) => {
            if (index === ind) {
                return {...row, noteOnTheLeaseOfProperty: checked};
            }
            return row;
        }));
    };

    const onPropertyInputChange = (index, field) => (e) => {
        const newRows = [...rows];

        const value = e.target.value;

        newRows[index][field] = value;
        setRows(newRows);
    };

    const personalonChange = (code, name) => (e) => {
        const {value} = e.target;
        const codeValues = values[personalName]?.[code] || {};

        setValues({
            ...values,
            [personalName]: {
                ...values[personalName],
                [code]: {
                    ...codeValues, [name]: value
                }
            }
        });
    }

    const incomesonChange = (code) => (e) => {
        const {value} = e.target;
        const v = values[incomesName] || {};

        setValues({...values, [incomesName]: {...v, [code]: value}});
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (status === 'loading') return;

        const body = {
            ...values,
            propertyInfoItems: rows.filter(row => !!row.categoriesOfObjects).map(({id, ...row}) => ({
                ...row,
                categoriesOfObjects: row.categoriesOfObjects.value,
            }))
        }

        updateIncomesInfo({token, year, ...body});
    };

    useEffect(() => {
        if (data) {
            const {propertyInfoItems, ...rest} = data;
            setValues(rest);
            setRows(propertyInfoItems.map((row) => (
                {
                    ...row,
                    categoriesOfObjects: options.find(({value}) => value === row.categoriesOfObjects)
                }
            )));
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            setValues({});
            setRows([]);
        }
    }, [error]);

    useEffect(() => {
        if (status === 'fulfilled') {
            setButtonText('Збережено');
        }
    }, [status])

    useEffect(() => {
        if (buttonText === 'Збережено') {
            setTimeout(() => setButtonText('Зберегти'), 2000);
        }
    }, [buttonText]);

    const RenderItem = (label, code, tooltip) => {
        const val = values[personalName]?.[code] || {};

        return (
            <div className="grid grid-cols-5 gap-1 items-center py-1.5 px-7 bg-gray-75 rounded-2xl lgdef:gap-5">
                <div className='col-span-2 flex items-center gap-2 mr-2'>
                    <label className="text-sm min-w-[150px] flex flex-1"> {label} </label>
                    <Tooltip
                        content={tooltip}
                        className="mr-auto"
                    />
                </div>
                {
                    [
                        "incomeSum",
                        "pdfoSelfPayable",
                        "militaryTaxSelfPayable"
                    ].map((inputName) => (
                        <input
                            key={inputName}
                            type="text"
                            className="border-none bg-white p-2 rounded-2xl h-11 focus:outline-none"
                            name={inputName}
                            onChange={personalonChange(code, inputName)}
                            value={val[inputName] || ''}
                        />
                    ))
                }
            </div>
        )
    }

    const RenderIncomeItem = (label, inputName, tooltip) => (
        <div className="grid grid-cols-5 gap-1 items-center py-1.5 px-7 bg-gray-75 rounded-2xl lgdef:gap-5">
            <div className='col-span-3 text-sm min-w-[150px] flex flex-1'>
                <label className="col-span-3 text-sm min-w-[150px] flex flex-1"> {label} </label>
                <Tooltip
                    content={tooltip}
                    className="mr-auto"
                />
            </div>
            <input
                type="text"
                className="col-span-2 border-none bg-white p-2 rounded-2xl h-11 focus:outline-none"
                name={inputName}
                onChange={incomesonChange(inputName)}
                value={values[incomesName]?.[inputName] || ''}
            />
        </div>
    );

    return (
        <form id='personalInfo' className="flex flex-col gap-4" onSubmit={onSubmit}>

            <div className="flex flex-col gap-2.5">
                <h4 className='text-lg text-black font-light'>
                    ДОХОДИ, ЯКІ ВКЛЮЧАЮТЬСЯ ДО ЗАГАЛЬНОГО РІЧНОГО ОПОДАТКОВУВАНОГО ДОХОДУ
                </h4>
                <p className="mb-4 text-gray-500">
                    Цей розділ дає змогу податковій службі визначити суму доходів, які підлягають оподаткуванню, та
                    обчислити податкові зобов’язання фізичної особи. Усі джерела доходів, які включаються до річного
                    оподатковуваного доходу, мають бути задекларовані повністю та точно.
                </p>
            </div>
            <div className="grid gap-0.5">
                <div
                    className="grid grid-cols-5 gap-1 items-stretch lgdef:gap-5 bg-gray-450 rounded-2xl px-6 py-3 mb-[-4px]">
                    {
                        [
                            "",
                            "Сума доходів (грн, коп.)",
                            "ПДФО що сплатив податковий агент (грн, коп.)",
                            "ВЗ що сплатив податковий агент (грн, коп.)"
                        ].map((name, ind) => <label key={name} className={
                            clsx("text-sm min-w-[150px] w-[70%]", ind === 0 && 'col-span-2')
                        }> {name} </label>)
                    }
                </div>
                {personalInfo.map(({label, code, tooltip}) => RenderItem(label, code, tooltip))}
            </div>

            {/* ///////////////////// */}

            <div className="flex flex-col gap-2.5">
                <h4 className='text-lg text-black font-light'>
                    Доходи що неоподатковуються
                </h4>
                <p className="mb-4 text-gray-500">
                    Доходи ФОП, доходи з Українських облігацій внутрішньої і зовнішньої державної позики, компенсації
                    від страхових випадків та інші доходи що не підлягають оподаткуванню
                </p>
            </div>

            <div className="grid gap-0.5">
                <div
                    className="grid grid-cols-5 gap-1 items-stretch lgdef:gap-5 bg-gray-450 rounded-2xl px-6 py-7 mb-[-4px]">
                    {
                        [
                            <span></span>,
                            <span>Сума доходів <br/> (грн, коп.) </span>,
                        ].map((name, ind) =>
                            <label key={ind} className={
                                clsx("text-sm", ind === 0 && 'col-span-3')
                            }>
                                {name}
                            </label>
                        )
                    }
                </div>
                {incomesInfo.map(({label, inputName, tooltip}) => RenderIncomeItem(label, inputName, tooltip))}
            </div>

            {/* ///////////////////// */}

            <div className="flex flex-col gap-2.5">
                <h4 className='text-lg text-black font-light'>
                    Відомості про особисте майно
                </h4>
                <p className="mb-4 text-gray-500">
                    Вказуються для фіксації того, що знаходиться у власності. Може стати у нагоді коли будете продавати,
                    на приклад, автомобіль вперше за рік і потім будете зазначати в декларації як доходи що
                    неоподатковуються.
                </p>
            </div>

            <div className="grid gap-0.5">
                <div
                    className="grid grid-cols-8 gap-1 items-stretch lgdef:gap-5 bg-gray-450 rounded-2xl px-6 py-7 mb-[-4px]">
                    {
                        [
                            <span>Тип <br/> майна </span>,
                            <span>	Адреса або <br/> модель майна </span>,
                            <span> Рік випуску/ <br/> придбання <br/> майна </span>,
                            <span> Загальна площа <br/> (кв. м) кожного <br/> окремого об'єкта </span>,
                            <span> Частка в загальній <br/> площі нерухомого <br/> майна </span>,
                            <span> Відмітка про <br/> надання майна <br/> в оренду </span>,
                        ].map((name, ind) => <label key={ind} className={
                            clsx("text-sm", [0, 1].includes(ind) && 'col-span-2')
                        }> {name} </label>)
                    }
                </div>

                {
                    rows.map((row, index) => {
                        return (
                            <Owns
                                key={row.id}
                                row={row}
                                ind={index}
                                handlePropertySelect={handlePropertySelect}
                                onPropertyInputChange={onPropertyInputChange}
                                onPropertyCheckboxChange={onPropertyCheckboxChange}
                            />
                        )
                    })
                }

                <div
                    className="grid grid-cols-5 gap-1 items-center py-5 px-7 bg-gray-75 rounded-2xl cursor-pointer"
                    onClick={() => setRows([...rows, {id: Date.now()}])}
                >
                    <label className="col-span-5 text-sm min-w-[150px] flex flex-1 text-gray-350 cursor-pointer"> +
                        додати поле </label>
                </div>
            </div>

            {/* ///////////////////// */}

            <button
                className={
                    clsx(
                        `	text-sm text-gray-350 mt-1 rounded-md py-2.5 px-4 w-fit
							bg-gradient-to-r from-cyan-650 to-cyan-75 text-white 
							hover:bg-gradient-to-r hover:from-cyan-900 hover:to-cyan-650
						`,
                        status === 'pending' && 'pointer-events-none'
                    )
                }
                type="submit"
            >
                {buttonText === 'Збережено' && <span className="mr-2">✓</span>}
                {status === 'pending' ? 'Збереження...' : buttonText}
            </button>
        </form>
    )
}

function Owns(props) {
    const {row, ind, handlePropertySelect, onPropertyInputChange, onPropertyCheckboxChange} = props;

    return (
        <div className="grid grid-cols-8 gap-1 items-center py-1.5 px-7 bg-gray-75 rounded-2xl lgdef:gap-5">
            <div className='col-span-2 flex items-center'>
                <Dropdown
                    options={options}
                    value={row.categoriesOfObjects}
                    onChange={handlePropertySelect(ind)}
                    noborder
                    className="text-sm bg-clip-text text-gray-350">
                    <span>{row?.categoriesOfObjects?.label || 'Оберіть категорію'}</span>
                </Dropdown>
            </div>
            <input
                type="text"
                className="col-span-2 border-none bg-white p-2 rounded-2xl h-11 focus:outline-none"
                onChange={onPropertyInputChange(ind, 'locationOfTheProperty')}
                value={row.locationOfTheProperty}
            />
            <input
                type="number"
                className="border-none bg-white p-2 rounded-2xl h-11 focus:outline-none"
                onChange={onPropertyInputChange(ind, 'yearOfAcquisition')}
                value={row.yearOfAcquisition}
            />
            <input
                type="number"
                className="border-none bg-white p-2 rounded-2xl h-11 focus:outline-none"
                onChange={onPropertyInputChange(ind, 'totalAreaOfRealEstate')}
                value={row.totalAreaOfRealEstate}
            />
            <input
                type="number"
                className="border-none bg-white p-2 rounded-2xl h-11 focus:outline-none"
                onChange={onPropertyInputChange(ind, 'shareInTheTotalAreaOfRealEstate')}
                value={row.shareInTheTotalAreaOfRealEstate}
            />

            <div className="flex items-center justify-center h-11 w-11 rounded-2xl bg-white">
                <div className={clsx(
                    'h-6 w-6 overflow-hidden rounded-[35%] cursor-pointer',
                    !row.noteOnTheLeaseOfProperty && 'border-gray-350 border'
                )}
                >
                    <input
                        type="checkbox"
                        className={clsx("h-6 w-6 accent-gray-350 cursor-pointer", !row.noteOnTheLeaseOfProperty && 'appearance-none')}
                        checked={row.noteOnTheLeaseOfProperty}
                        onChange={onPropertyCheckboxChange(ind)}
                    />
                </div>
            </div>

        </div>
    );

}

export default IncomesPage;
