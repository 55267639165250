import './index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import App from './App';
import { NavigationProvider } from './context/navigation';
import { AuthProvider } from 'react-oidc-context';
import { KEYCLOAK_HOST, KEYCLOAK_CLIENT_ID } from './constants/constants';

const el = document.getElementById('root');
const root = createRoot(el);
const oidcConfig = {
  authority: KEYCLOAK_HOST,
  client_id: KEYCLOAK_CLIENT_ID,
  redirect_uri: window.location.href,
  id_token_signing_alg_values_supporte: ['RS256'],
  post_logout_redirect_uri: window.location.origin,
  // client_id: 'osa',
  // authority: 'http://localhost:8080/realms/osa',
};

root.render(
  <Provider store={store}>
    <AuthProvider {...oidcConfig}>
      <NavigationProvider>
        <App />
      </NavigationProvider>
    </AuthProvider>
  </Provider>,
);
