import Link from "../components/Link";
import ImageShow from "../components/ImageShow";
import AccordionTax from "../components/AccordionTax";

function DeclarationPage() {
  const data = [
    {
      name: "Декларації 2020",
      income: "15000 грн.",
      taxForPay: "3600 грн.",
      files: [
        { name: "Декларація про майновий стан та доходи" },
        { name: "Форма Ф1" },
        { name: "Супроводжуючий лист" },
      ],
    },
    {
      name: "Декларації 2021",
      income: "15000 грн.",
      taxForPay: "3600 грн.",
      files: [
        { name: "Декларація про майновий стан та доходи" },
        { name: "Форма Ф1" },
        { name: "Супроводжуючий лист" },
      ],
    },
    {
      name: "Декларації 2022",
      income: "15000 грн.",
      taxForPay: "3600 грн.",
      files: [
        { name: "Декларація про майновий стан та доходи" },
        { name: "Форма Ф1" },
        { name: "Супроводжуючий лист" },
      ],
    },
    {
      name: "Декларації 2023",
      income: "15000 грн.",
      taxForPay: "3600 грн.",
      files: [
        { name: "Декларація про майновий стан та доходи" },
        { name: "Форма Ф1" },
        { name: "Супроводжуючий лист" },
      ],
    },
  ];

  const items = data.map((account, index) => {
    let broker;
    return {
      id: index,
      label: account,
      content: account.files,
    };
  });

  return (
    <div className="page">
      <div className="flex items-center mb-2 sm:mb-6">
        <Link
          to="/taxreports"
          icon="back"
          className="text-gray-350 text-sm block"></Link>
        <h1 className="text-black text-lg sm:text-2xl">Готові декларації</h1>
      </div>

      <div className="flex sm:items-center">
        <Link
          to="/taxreports"
          className="text-xs text-gray-350 hover:underline">
          Податкові звіти |
        </Link>
        <span className="text-xs ml-1">Готові декларації</span>
        <Link
          to="/declaration"
          className="-mt-[34px] rounded-xl h-22 bg-gradient-to-r p-[2px] 
          from-cyan-650 to-cyan-75 place-self-end self-center ml-auto">
          <div className="flex justify-between h-full bg-white text-white rounded-lg p-2">
            <ImageShow
              className="mr-2"
              image={{ url: "video", label: "video" }}
            />
            <span className="text-sm text-black">
              Інструкція{" "}
              <span className="hidden sm:inline">подачі декларації</span>
            </span>
          </div>
        </Link>
      </div>
      <div className="hidden w-full items-center mt-16 h-[80px] flex pl-6 border-transparent rounded-xl text-white bg-gradient-to-r from-cyan-650 to-cyan-75">
        <p>
          У вас немає жодної створенної декларації. Для створення перейдіть на
          сторінку{" "}
          <Link to="/taxreports" className="underline hover:no-underline">
            “Податкові звіти”
          </Link>
        </p>
      </div>
      {items && <AccordionTax items={items} />}
    </div>
  );
}

export default DeclarationPage;
