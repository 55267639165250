import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { Provider } from 'react-redux';
import App from './App';
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_HOST } from './constants/constants';
import { NavigationProvider } from './context/navigation';
import './index.css';
import { store } from './store';

const el = document.getElementById('root');
const root = createRoot(el);
const oidcConfig = {
  authority: KEYCLOAK_HOST,
  client_id: KEYCLOAK_CLIENT_ID,
  redirect_uri: window.location.href,
  id_token_signing_alg_values_supporte: ['RS256'],
  post_logout_redirect_uri: window.location.origin,
  // client_id: 'osa',
  // authority: 'http://localhost:8080/realms/osa',
};

// const appHeight = () => {
//   let vh = window.innerHeight * 0.01;
//   document.documentElement.style.setProperty('--vh', `${vh}px`);
//  }
//  window.addEventListener("resize", appHeight)
//  appHeight()

// const setMobileSafariHeight = () => {
//   const
//     is_ios = /iP(ad|od|hone)/i.test(window.navigator.userAgent),
//     is_safari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

//   document.documentElement.style.setProperty('--mb', `0px`);

//   if (is_ios && is_safari) {
//     const navHeight = 80;
//     document.documentElement.style.setProperty('--mb', `${navHeight}px`);
//   }
// };
// setMobileSafariHeight();

root.render(
  <Provider store={store}>
    <AuthProvider {...oidcConfig}>
      <NavigationProvider>
        <App />
      </NavigationProvider>
    </AuthProvider>
  </Provider>,
);
