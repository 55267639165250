import Link from "../components/Link";
import ImageShow from "../components/ImageShow";

function TaxContent({ content, isFetching }) {
  return (
    <div className="mt-16">
      {content}
      {!isFetching && (
        <>
          <Link
            to="/declaration"
            className="rounded-xl h-22 flex w-[259px] mt-7 bg-gradient-to-r p-[2px] from-cyan-650 to-cyan-75 mr-6">
            <div className="flex justify-between h-full rounded-lg p-2">
              <ImageShow
                className="mr-2"
                image={{ url: "video-white", label: "video white" }}
              />
              <span className="text-sm text-white">
                Інструкція подачі декларації
              </span>
            </div>
          </Link>
          <div className="h-10 mt-16 text-gray-350 text-sm pt-2 pl-3 rounded-lg bg-gray-25 w-full">
            Якщо ви бажаєте внести зміни у декларацію, тоді завантажте нові
            <Link to="/reports" className="underline">
              рахунки
            </Link>
          </div>
        </>
      )}
    </div>
  );
}

export default TaxContent;
