import classNames from "classnames";

function Panel({ children, className, heading, ...rest }) {
  const finalClassNames = classNames("rounded bg-white w-full", className);

  return (
    <div {...rest} className={finalClassNames}>
      <div className="p-[30px] bg-gray-75 rounded-t-2xl text-white bg-gradient-to-r from-cyan-650 to-cyan-75">
        {heading}
      </div>
      <div className="p-[30px] bg-zinc-50 rounded-b-2xl">{children}</div>
    </div>
  );
}

export default Panel;
