import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { GoSync } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from '../components/Accordion';
import Button from '../components/Button';
import DragDrop from '../components/DragDrop';
import ImageShow from '../components/ImageShow';
import Link from '../components/Link';
import UploadedAccounts from '../components/UploadedAccounts';
import { FREEDOM_TYPE, INTERACTIVE_TYPE } from '../constants/constants';
import useBroker from '../hooks/use-broker';
import useToken from '../hooks/use-token';
import {
  uploadFreedomFiles,
  uploadInteractiveFiles, useSynchronizeFilesMutation, useSynchronizingFilesQuery
} from '../store';

function Upload() {
  const token = useToken();
  const dispatch = useDispatch();
  const [synchronizeFiles] = useSynchronizeFilesMutation();
  const [synchronizing, setSynchronizing] = useState(false);
  let { broker, brokerType } = useBroker();

  // After page is reloaded broker is underfined
  if (!broker && !brokerType) {
    broker = JSON.parse(localStorage.getItem('broker'));
    if (broker === 'freedom') {
      brokerType = FREEDOM_TYPE;
    } else if (broker === 'interactive') {
      brokerType = INTERACTIVE_TYPE;
    }
  }

  const params = {
    token,
    dataProvider: brokerType,
  };

  const { data, error, isFetching } = useSynchronizingFilesQuery({
    params,
  });

  useEffect(() => {
    if (data) {
      setSynchronizing(true);
    }
  }, [data]);

  let content;
  if (isFetching) {
    content = (
      <div className="pl-16 pr-8 mt-20">
        <GoSync className="animate-spin inline-block text-green-700" />
      </div>
    );
  } else if (error) {
    content = <div>Error loading...</div>;
  } else {
    content = <UploadedAccounts synchronizingData={data} />;
  }

  const files = useSelector((state) => {
    if (broker === 'freedom') {
      return state.file.freedomFiles;
    } else {
      return state.file.interactiveFiles;
    }
  });

  const fileError = files.find((file) => file.error);

  const synchronizeData = () => {
    const ids = files.map((file) => {
      return file.data.id;
    });
    if (broker === 'freedom') {
      dispatch(uploadFreedomFiles([]));
    } else {
      dispatch(uploadInteractiveFiles([]));
    }
    synchronizeFiles({ token, ids });
    setSynchronizing(true);
    setTimeout(() => {
      scrollToAccounts();
    }, 400);
  };

  const scrollToAccounts = () => {
    const el = document.querySelector('h4');
    if (el) {
      window.scrollTo({
        top: el.getBoundingClientRect().top,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const instructions = useMemo(() => broker === 'freedom' ? (
    <ol className="list-decimal text-sm">
      <li>
        Увійдіть на власний брокерський рахунок через{' '}
        <a
          className="text-cyan-650 hover:underline"
          href="http://tradernet.com/">
          tradernet.com
        </a>
      </li>
      <li>У правому-верхньому куті розгорніть меню та оберіть Звіти брокера</li>
      <li>У правому-верхньому куті змініть мову на ENG (Англійську)</li>
      <li>
        В полі “Report for the period” оберіть період з дати відкриття рахунку
        по 31 грудня останнього повного року (якщо ви не пам’ятаєте в якому році
        відкрили рахунок - оберіть початковий період 01/01/2012)
      </li>
      <li>Натисніть підтвердити</li>
      <li>Після формування звіту - завантажте документ у форматі JSON</li>
      <li>
        Повторіть такі самі дії для “D” рахунку, якщо ви ним користувались
      </li>
      <li>Завантажте створені файли у нашу систему</li>
    </ol>
  ) : (
    <ol className="list-decimal text-sm">
      <li>
        Увійдіть на власний брокерський рахунок через{' '}
        <a
          className="text-cyan-650 hover:underline"
          href="http://interactivebrokers.com/">
            interactivebrokers.com
        </a>
      </li>
      <li>Перейдіть до розділу <b> “Performance & Reports” </b> (“Доходность и отчеты”) з подальшим вибором <b>“Statements”</b> (“Выписки”)</li>
      <li>Оберіть <b> “Default Statements” </b> - тип “Activity” натиснувши на синю стрілку</li>
      <li>  Період оберіть річний - <b> “Annual” </b> </li>
      <li> В полі дата оберіть рік, за який ви хочете отримати звіт </li>
      <li> Мова - <b> англійська </b> </li>
      <li>
        Скачайте звіт у форматі <b> .CSV </b>
      </li>
      <li>Завантажте створені файли у нашу систему</li>
    </ol>
  ), [broker]);

  const items = [
    {
      id: 0,
      label: 'Інструкція',
      content: instructions,
    },
  ];

  return (
    <>
      <div className="page flex flex-col xl:flex-row">
        <div className="xl:max-w-[700px]">
          <Link
            to="/accounts"
            icon="back"
            className="text-gray-350 text-sm block pb-4">
            {broker && (
              <ImageShow
                className="ml-2"
                image={{ url: broker, label: broker }}
              />
            )}
          </Link>
          <h1 className="text-black text-2xl pb-3">Завантажити файл</h1>
          <p className="text-sm text-gray-350 pb-7">
            Завантажте файли операцій за всі роки торгівлі та завантажте їх
            тут. Кожен депозит, зняття та угода мають бути додані
          </p>

          <DragDrop />
          {/* {!fileError && files.length > 0 && ( */}
            <Button
              onClick={synchronizeData}
              className={clsx(
                "w-full h-[60px] flex justify-center border-transparent rounded-xl text-white user-select-none mt-2",
                (!fileError && files.length > 0) 
                  ? "from-cyan-650 to-cyan-75 hover:from-cyan-900 hover:to-cyan-650 bg-gradient-to-r"
                  : "from-cyan-650 to-cyan-75 pointer-events-none bg-gradient-to-tr opacity-50"
              )}
            >
                Опрацювати
            </Button>
          {/* )} */}
          {synchronizing && data?.synchronizingInvestingAccounts.length > 0 && (
            <Button
              onClick={scrollToAccounts}
              className="text-sm xl:mx-auto mt-5 border-transparent bg-clip-text text-transparent bg-gradient-to-r from-cyan-650 to-cyan-75 hover:from-cyan-900 hover:to-cyan-650">
              <ImageShow
                className="ml-2 mr-2"
                image={{ url: 'down', label: 'Down' }}
              />
              Повернутися до завантажених рахунків
            </Button>
          )}
        </div>
        <div className="border-l-[#f2f2f2] hidden xl:block border-l border-solid pl-10 ml-28 py-8 pr-10">
          <h3 className="text-base pb-4 -ml-4">Інструкція</h3>
          {instructions}
        </div>
        <Accordion className="block xl:hidden" items={items} />
      </div>
      {synchronizing && content}
    </>
  );
}

export default Upload;
