import classNames from "classnames";
import useNavigation from "../hooks/use-navigation";

import ImageShow from "../components/ImageShow";

function Link({
  to,
  children,
  className,
  activeClassName,
  icon,
  iconClass,
  handleClick,
  broker,
  ...rest
}) {
  const { navigate, currentPath } = useNavigation();
  const classes = classNames(
    "",
    className,
    currentPath === to && activeClassName,
    currentPath === "/accounts" && to === "/reports" && activeClassName,
    currentPath === "/upload" && to === "/reports" && activeClassName,
    currentPath === "/upload" && to === "/accounts" && activeClassName,
    currentPath === "/reports" && to === "/accounts" && activeClassName,
    currentPath === "/declaration" && to === "/taxreports" && activeClassName,
    currentPath === "/referral-info" && to === "/referral" && activeClassName
  );

  if (!handleClick) {
    handleClick = (event) => {
      if (event.metaKey || event.ctrlKey) {
        return;
      }
      event.preventDefault();

      navigate(to);
    };
  }

  return (
    <a
      className={classes}
      href={to}
      onClick={handleClick}
      broker={broker}
      {...rest}>
      {icon && (
        <ImageShow
          className={iconClass}
          image={{ url: icon, label: children }}
        />
      )}
      {children}
    </a>
  );
}

export default Link;
